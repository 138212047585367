import {StrapiId, StrapiNested} from "./StrapiApi";
import {StrapiCustomer} from "./StrapiCustomer";
import {StrapiModel} from "./StrapiModel";
import dayjs, {Dayjs} from "dayjs";

export interface StrapiVehicle {
    customer?: StrapiNested<StrapiCustomer>
    model: StrapiNested<StrapiModel>,
    number: string,
    kilometers?: number,
    label?: string,
    vin: string,
    registration: string,
    inspection?: string
}

export interface StrapiVehicleForm extends Omit<StrapiVehicle, 'customer' | 'model' | 'registration'> {
    manufacturerId: StrapiId,
    modelId: StrapiId,
    registrationDate: Dayjs,
    inspectionDate?: Dayjs
}

export const convertVehicleFormData = (data: StrapiVehicle): StrapiVehicleForm => {
    return {
        ...data,
        manufacturerId: data.model!.data!.attributes.manufacturer!.data!.id,
        modelId: data.model!.data!.id,
        registrationDate: dayjs(data.registration),
        inspectionDate: data.inspection ? dayjs(data.inspection) : undefined
    };
}