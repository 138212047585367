import React, {useContext} from 'react';
import {Button, DatePicker, Form, Input, Select, TimePicker} from 'antd';
import {useForm} from 'antd/es/form/Form';
import StrapiContext from "../../context/StrapiContext";

const {Option} = Select;

interface Props {
    submit: () => void
}

const CalendarCreate = ({submit}: Props) => {
    const [form] = useForm();
    const {vehicles} = useContext(StrapiContext);

    const dataSource = (vehicles ?? [])
        .map(v => ({id: v.id, ...v.attributes}))

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={submit}
            initialValues={{
                appointmentType: 'Service',
                vehicle: 'None',
            }}
        >
            <Form.Item
                name="appointmentType"
                label="Art des Termins"
                rules={[{required: true, message: 'Bitte wählen Sie die Art des Termins aus'}]}
            >
                <Select>
                    <Option value="Service">Service</Option>
                    <Option value="Beratung">Beratung</Option>
                    <Option value="Probefahrt">Probefahrt</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="vehicle"
                label="Fahrzeug"
                rules={[{required: true, message: 'Bitte wählen Sie ein Fahrzeug aus'}]}
            >
                <Select>
                    <Option value="None">Kein Fahrzeug</Option>
                    {dataSource.map(x => (
                        <Option value={x.id}>{x.number}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="appointmentDate"
                label="Datum des Termins"
                rules={[{required: true, message: 'Bitte wählen Sie das Datum des Termins aus'}]}
            >
                <DatePicker style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item
                name="appointmentTime"
                label="Uhrzeit des Termins"
                rules={[{required: true, message: 'Bitte wählen Sie die Uhrzeit des Termins aus'}]}
            >
                <TimePicker format="HH:mm" style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item
                name="comments"
                label="Kommentare"
            >
                <Input.TextArea rows={4}/>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Termin vereinbaren
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CalendarCreate;
