import {Card, Row} from "antd";
import React from "react";

import {Col3} from "../../layout/Grid";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";

const TODOs = () => {
    return (
        <Card>
            <strong>Weiterer Ausbau der Demo - mögliche TODOs:</strong>
            <Row gutter={VIEW_GUTTER}>
                <Col3>
                    <p>Shop Features</p>
                    <ul>
                        <li>! Sporadisches Shop-Login Issue (Context Token)</li>
                        <li>Gelöschtes Produkt in Warenkorb/Config - was tun?</li>
                        <li>Produkt-Detailseite: Variantenswitcher, Advanced Prices, Verfügbarkeit, ...</li>
                        <li>Restliche CMS Blocks + Elements inkl. Config-Varianten</li>
                        <li>Categories: Reference Links</li>
                    </ul>
                </Col3>
                <Col3>
                    <p>Data Models + Views</p>
                    <ul>
                        <li>Account-/Adress-Management</li>
                        <li>Terminkalender - Termin vereinbaren</li>
                        <li>Modelle - Probefahrt vereinbaren</li>
                        <li>Dokumente</li>
                    </ul>
                </Col3>
                <Col3>
                    <p>UI / Sonstiges</p>
                    <ul>
                        <li>Mobile Header / Navigation</li>
                        <li>Sub-Navigation Header, Footer- und Service-Navigation, Frontend-Views via Categories injecten</li>
                        <li>Bestand: Weitere Fahrzeug-Filter (Modell, Zulassung, Kilometer, Antrieb, Kraftstoff, etc etc ...)</li>
                        <li>Konzept kundenspezifische Themes/CI/Views</li>
                    </ul>
                </Col3>
            </Row>
        </Card>
    )
}

export default TODOs;