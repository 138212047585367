import React, {useContext, useEffect} from "react";
import {Alert, Card, Col, Row} from "antd";
import sortBy from "lodash.sortby";

import LocalizationContext from "../../context/LocalizationContext";
import Headline from "../../layout/Headline";
import Breadcrumbs from "../../layout/Breadcrumbs";
import StrapiContext from "../../context/StrapiContext";
import Date from "../../shared/Date";
import Time from "../../shared/Time";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import CalendarCreate from "./CalendarCreate";
import {Link, useLocation} from "react-router-dom";
import {ROUTE_CALENDAR, ROUTE_CALENDAR_CREATE} from "../../../constant/route";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const Calendar = () => {
    const {t} = useContext(LocalizationContext);
    const {strapiCustomer} = useContext(StrapiContext);
    const location = useLocation();

    const [create, setCreate] = React.useState(location.pathname === ROUTE_CALENDAR_CREATE);
    const [submitted, setSubmitted] = React.useState(false);

    const calendars = strapiCustomer?.attributes.calendars?.data;

    useEffect(() => {
        setCreate(location.pathname === ROUTE_CALENDAR_CREATE);
        setSubmitted(false)
    }, [location.pathname]);

    return (
        <>
            <Breadcrumbs/>
            <Headline actions={!create && <Link
              to={ROUTE_CALENDAR_CREATE}>{t("route." + ROUTE_CALENDAR_CREATE)}</Link>}>{t("calendar.headline")}</Headline>
            {(!calendars || calendars.length === 0) &&
              <Alert message={t("calendar.empty")}/>}

            {calendars && sortBy(calendars, ["attributes.date", "attributes.timeStart"])
                .map(calendar => (
                    <Card size={"small"} key={calendar.id}>
                        <Row gutter={VIEW_GUTTER} align={"middle"}>
                            <Col span={6}>
                                <Date value={calendar.attributes.date} format={"dddd, DD. MMMM. YYYY"}/>
                            </Col>
                            <Col span={4}>
                                <Time value={calendar.attributes.timeStart}/>
                                {calendar.attributes.timeEnd && <>-<Time value={calendar.attributes.timeEnd}/></>}
                            </Col>
                            <Col span={14}>
                                <strong>{calendar.attributes.label}</strong>
                            </Col>
                        </Row>
                    </Card>
                ))}

            {create &&
              <Card style={{marginTop: "1.5rem"}} title={t("route." + ROUTE_CALENDAR_CREATE)}
                    extra={<Link to={ROUTE_CALENDAR}><FontAwesomeIcon icon={faTimes}/></Link>}>
                  {submitted ?
                      <Alert
                          message={"Vielen Dank für Ihre Terminanfrage, wir werden uns schnellstmöglich bei Ihnen\n" +
                              "                          melden!"}/> :
                      <CalendarCreate submit={() => setSubmitted(true)}/>}
              </Card>}
        </>
    )
}

export default Calendar;