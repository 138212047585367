import React, {ReactNode, useContext} from "react";
import {ConfigProvider as AntConfigProvider} from 'antd';

import localeDE from 'antd/lib/locale/de_DE';
import localeEN from 'antd/lib/locale/en_GB';
import {LanguageCode} from "../../type/StrapiCustomer";
import StrapiContext from "../context/StrapiContext";
import themeDefault from "../../theme/themeDefault";
import themeBundk from "../../theme/themeBundk";
import themeAutoweller from "../../theme/themeAutoweller";
import ThemeProvider from "../context/ThemeProvider";
import {Theme as ThemeT} from "../../type/Theme";

interface Props {
    children: ReactNode
}

const getTheme = () => {
    const themeName = process.env.REACT_APP_THEME;
    switch (themeName) {
        case "bundk":
            return themeBundk;
        case "autoweller":
            return themeAutoweller;
        default:
            return themeDefault;
    }
}

const mapCssVariables = (theme: ThemeT) => {
    const mappings: { [key: string]: any } = {
        '--primary': theme.themeConfig.token?.colorPrimary,
        '--border': theme.themeConfig.token?.colorBorder,
        '--body': theme.colorBgBody
    }

    Object.keys(mappings).forEach(property => {
        document.documentElement.style.setProperty(property, mappings[property] ?? null);
    })
}

const Theme = ({children}: Props) => {
    const {language} = useContext(StrapiContext);
    const theme = getTheme();
    mapCssVariables(theme);

    return (
        <ThemeProvider themeContext={theme}>
            <AntConfigProvider
                locale={language === LanguageCode.DE ? localeDE : localeEN}
                theme={theme.themeConfig}>
                {children}
            </AntConfigProvider>
        </ThemeProvider>
    )
}

export default Theme;