interface Props {
    value?: string
}

const Time = ({value}: Props) => {
    if (value === undefined) return <></>;

    return <>{value.substring(0, 5)}</>
}

export default Time;