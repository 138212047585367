import {Col, Row, Typography} from "antd";
import React, {ReactNode} from "react";

interface Props {
    level?: 1 | 2 | 3,
    children: ReactNode,
    actions?: ReactNode,
    noMargin?: boolean,
    style?: React.CSSProperties
}

const Headline = ({style, children, actions, level = 1, noMargin = false}: Props) => {
    const s: React.CSSProperties = {marginTop: 0, marginBottom: 0};

    if (!noMargin) {
        s.marginBottom = "1.5rem";

        if (level === 2) {
            s.marginBottom = "1.25rem";
            s.marginTop = "2rem";
        } else if (level === 3) {
            s.marginBottom = "1rem"
        }
    }

    return (
        <div>
            <Row justify={"space-between"} style={s}>
                <Col>
                    <Typography.Title level={level} style={{...style, margin: 0}}>
                        {children}
                    </Typography.Title>
                </Col>
                {actions && <Col>{actions}</Col>}
            </Row>
        </div>
    )
}

export default Headline;