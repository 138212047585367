export const LOCAL_STORAGE_LOGIN = "remember_login";

export const setStorageValue = (key: string, value: any) => {
    if (value === null || value === undefined) localStorage.removeItem(key);
    else localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageValue = (key: string, defaultValue: any = undefined) => {
    try {
        const value = JSON.parse(localStorage.getItem(key)!);
        if (value === null) return defaultValue;
        else return value;
    } catch (e) {
        console.log("invalid localStorage value", key, localStorage.getItem(key));
        return defaultValue;
    }
};

export const clearStorageValue = (key: string) => {
    localStorage.removeItem(key);
}
