import React from "react";
import {Row} from "antd";

import Slot from "../Slot";
import {CMS_GUTTER} from "../../../constant/cmsConfig";
import {BlockProps} from "../../../type/CmsPage";
import {Col2} from "../../layout/Grid";

const TextTwoColumnBlock = ({block, category}: BlockProps) => {
    const left = block.slots.find(slot => slot.slot === "left");
    const right = block.slots.find(slot => slot.slot === "right");

    return (
        <Row gutter={CMS_GUTTER}>
            <Col2>
                {left && <Slot slot={left} category={category} columns={2}/>}
            </Col2>
            <Col2>
                {right && <Slot slot={right} category={category} columns={2}/>}
            </Col2>
        </Row>
    )
}

export default TextTwoColumnBlock;