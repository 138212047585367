import {StrapiNested, StrapiNestedArray} from "./StrapiApi";
import {StrapiContact} from "./StrapiContact";
import {StrapiJsonLineItem} from "./StrapiLineItem";
import {StrapiMessage} from "./StrapiMessage";
import {StrapiCalendar} from "./StrapiCalendar";

export enum LanguageCode {
    DE = "de-DE",
    EN = "en-GB"
}

export interface StrapiCustomer {
    firstName: string,
    lastName: string,
    email: string,
    language: LanguageCode,
    password: string,
    active: boolean,
    contact?: StrapiNested<StrapiContact>,
    street: string,
    zip: string,
    city: string,
    cart: StrapiJsonLineItem[],

    messages?: StrapiNestedArray<StrapiMessage>,
    calendars?: StrapiNestedArray<StrapiCalendar>,

    shopwareId: string,
    shopwarePassword: string,
}