import React, {useContext, useState} from "react";
import {ClientApiError} from "@shopware-pwa/types";
import {StrapiError} from "strapi-sdk-js";
import {Alert, Button} from "antd";

import ShopwareContext from "../context/ShopwareContext";
import LocalizationContext from "../context/LocalizationContext";
import StrapiContext from "../context/StrapiContext";
import {ROUTE_ORDERS} from "../../constant/route";
import {StrapiId} from "../../type/StrapiApi";

interface Props {
    selectedProducts: string[],
    selectedConfigs: StrapiId[]
}

const OrderSubmit = ({selectedProducts, selectedConfigs}: Props) => {
    const {createShopOrder} = useContext(ShopwareContext)
    const {createOrder, cartQuantity, strapiCustomer, configurations, clearCart} = useContext(StrapiContext);
    const {t} = useContext(LocalizationContext);

    const [error, setError] = useState<ClientApiError | StrapiError | Error>();
    const [loading, setLoading] = useState<boolean>(false);

    const create = async () => {
        setLoading(true);

        try {
            // Product Order
            const productLineItems = (strapiCustomer?.attributes.cart || [])
                .filter(l => selectedProducts.includes(l.shopwareProductId))

            if (productLineItems.length > 0) {
                const order = await createShopOrder(productLineItems, false)
                await createOrder(order.id);
                clearCart(selectedProducts);
            }

            // Config Orders
            for (let i = 0; i < selectedConfigs.length; i++) {
                const config = configurations.find(c => c.id === selectedConfigs[i]);
                if (!config) continue;

                if (config.attributes.cart.length > 0) {
                    const order = await createShopOrder(config.attributes.cart, true)
                    await createOrder(order.id, config.id);
                } else {
                    await createOrder(undefined, config.id);
                }
            }

            window.location.hash = ROUTE_ORDERS

        } catch (error: any) {
            setError(error);
            setLoading(false)
        }
    }

    return (
        <div style={{marginTop: "2rem"}}>
            {error && <div style={{marginBottom: "1rem"}}>
                {"messages" in error
                    ? error.messages.map(message => (
                        <Alert message={message.title} description={message.detail} showIcon={true} key={message.detail}
                               type={"error"}/>
                    ))
                    : "error" in error
                        ? <Alert message={error.error.name} description={error.error.message} showIcon={true}
                                 type={"error"}/>
                        : <Alert message={error.name} description={error.message} showIcon={true} type={"error"}/>}
            </div>}
            <div style={{textAlign: "right"}}>
                <Button type="primary" onClick={create} loading={loading} disabled={cartQuantity <= 0}>
                    {t("orderSubmit.submit")}
                </Button>
            </div>
        </div>
    )
}

export default OrderSubmit;