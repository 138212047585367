import {theme} from "antd";
import {Theme} from "../type/Theme";

// https://ant.design/docs/react/customize-theme#api
const themeDefault: Theme = {
    themeConfig: {
        token: {
            colorPrimary: '#00a6e2',
            colorLink: '#00a6e2',
            colorBorder: '#eeeeee',
            borderRadius: 2,
            colorBgContainer: '#fff',
            colorBgLayout: '#f4f4f4',
            fontSizeHeading1: 30,
            fontSizeHeading2: 24,
            fontSizeHeading3: 18,
        },
        components: {
            Layout: {
                headerBg: '#000000',
                headerColor: '#fff',
                headerHeight: 64,
                footerBg: '#000000',
            },
            Collapse: {
                headerBg: '#fff',
                contentBg: '#fff',
            }
        },
        algorithm: theme.defaultAlgorithm
    },
    headerMenuTheme: "dark",
    sidebarMenuTheme: "light",
    colorBgSidebar: "#f4f4f4",
    containerWidthInner: "1280px",
    showHeaderSpacer: true
}

export default themeDefault;