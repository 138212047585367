import React, {useContext} from "react";
import {Row} from "antd";
import classNames from "classnames";

import LocalizationContext from "../../context/LocalizationContext";
import {PaymentType, StrapiConfigurationForm} from "../../../type/StrapiConfiguration";
import Headline from "../../layout/Headline";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import {Col2} from "../../layout/Grid";

interface Props {
    configuration: StrapiConfigurationForm,
    updateConfiguration: (configuration: Partial<StrapiConfigurationForm>) => void
}

const Configure4 = ({configuration, updateConfiguration}: Props) => {
    const {t} = useContext(LocalizationContext)
    return (
        <>
            <Headline level={2}>{t("configuration.payment.headline")}</Headline>
            <p>{t("configuration.payment.text")}</p>
            <Row gutter={VIEW_GUTTER}>
                <Col2>
                    <div className={classNames("card-select",
                        {"selected": configuration.paymentType === PaymentType.FINANCING})}
                         onClick={() => updateConfiguration({paymentType: PaymentType.FINANCING})}>
                        <Headline level={3}>{t("configuration.payment.financing.headline")}</Headline>
                        <p>{t("configuration.payment.financing.text")}</p>
                    </div>
                </Col2>
                <Col2>
                    <div className={classNames("card-select",
                        {"selected": configuration.paymentType === PaymentType.DIRECT})}
                         onClick={() => updateConfiguration({paymentType: PaymentType.DIRECT})}>
                        <Headline level={3}>{t("configuration.payment.direct.headline")}</Headline>
                        <p>{t("configuration.payment.direct.text")}</p>
                    </div>
                </Col2>
            </Row>
        </>
    )
}

export default Configure4;