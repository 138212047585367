import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Category} from "@shopware-pwa/types";
import {Alert, Skeleton} from "antd";

import ShopwareContext from "../context/ShopwareContext";
import LocalizationContext from "../context/LocalizationContext";
import Section from "./Section";
import Breadcrumbs from "../layout/Breadcrumbs";
import "./CategoryPage.scss";
import {ROUTE_CATEGORY} from "../../constant/route";

interface RouteParams extends Record<string, string> {
    id: string
}

const CategoryPage = () => {
    const {t} = useContext(LocalizationContext);
    const {getCategory, shopwareLanguageId} = useContext(ShopwareContext);
    const {id} = useParams<RouteParams>();

    const [category, setCategory] = useState<Category>()
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setError(false)
        setCategory(undefined)

        if (id) getCategory(id)
            .then(c => setCategory(c))
            .catch(() => setError(true))
    }, [getCategory, id, shopwareLanguageId])

    return (
        <>
            <Breadcrumbs route={ROUTE_CATEGORY} title={category?.translated.name ?? "..."}/>
            {error && <Alert type={"error"} message={t("category.error")} showIcon={true}/>}
            {!error && !category && <Skeleton active={true}/>}
            {category && <div className={"ssys-category-page"}>
                {category.cmsPage && category.cmsPage.sections.map(section =>
                    <Section key={category.id + section.position} section={section} category={category}/>)}
            </div>}
        </>

    )
}

export default CategoryPage;