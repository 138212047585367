import React, {PureComponent, ReactElement, type ReactNode} from 'react';
import get from 'lodash.get';
import nl2br from 'react-nl2br';
import dayjs from "dayjs";
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';

import LocalizationContext from './LocalizationContext';
import locale_DE from '../../constant/locale_DE';
import locale_EN from "../../constant/locale_EN";
import withLanguage, {WithStrapi} from "./withStrapi";
import {LanguageCode} from "../../type/StrapiCustomer";

interface Props extends WithStrapi {
    children: ReactNode
}

interface State {
    source: object
}

type Values = Record<string, any>;

class LocalizationProvider extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            source: locale_DE
        };
    }

    componentDidMount() {
        this._setLanguage();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.language !== prevProps.language) this._setLanguage();
    }

    _setLanguage() {
        if (this.props.language === LanguageCode.DE) {
            dayjs.locale("de");
            this.setState({source: locale_DE})
        } else {
            dayjs.locale("en-gb");
            this.setState({source: locale_EN})
        }
    }

    /**
     * @param key - example: "x.y.z" to match object hierarchy
     * @param values  - example: {n: 1} to set value {n} in localization string
     */
    tStr = (key: string, values: Values = {}): string => {
        // if no localization exists, print key instead
        let localizedString = get(this.state.source, key, key);

        if (typeof localizedString === 'object') {
            localizedString = '[object] ' + key;
        }

        Object.keys(values).forEach(key => {
            localizedString = localizedString.replace('{' + key + '}', values[key]);
        });

        return localizedString;
    }

    t = (key: string, values: Values = {}): ReactElement => {
        const localizedString = this.tStr(key, values);
        return localizedString.includes('\n') ? <>{nl2br(localizedString)}</> : <>{localizedString}</>;
    }

    render() {
        return (
            <div lang={this.props.language}>
                <LocalizationContext.Provider value={{t: this.t, tStr: this.tStr}}>
                    {this.props.children}
                </LocalizationContext.Provider>
            </div>
        )
    }
}

export default withLanguage(LocalizationProvider);