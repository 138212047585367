import React, {useContext} from "react";
import {Row} from "antd";
import classNames from "classnames";

import LocalizationContext from "../../context/LocalizationContext";
import {StrapiConfigurationForm} from "../../../type/StrapiConfiguration";
import Headline from "../../layout/Headline";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import {Col2} from "../../layout/Grid";

interface Props {
    configuration: StrapiConfigurationForm,
    updateConfiguration: (configuration: Partial<StrapiConfigurationForm>) => void
}

const Configure2 = ({configuration, updateConfiguration}: Props) => {
    const {t} = useContext(LocalizationContext)
    return (
        <>
            <Headline level={2}>{t("configuration.registration.headline")}</Headline>
            <p>{t("configuration.registration.text")}</p>
            <Row gutter={VIEW_GUTTER}>
                <Col2>
                    <div className={classNames("card-select", {"selected": configuration.registrationService})}
                         onClick={() => updateConfiguration({registrationService: true})}>
                        <Headline level={3}>{t("configuration.registration.yes.headline")}</Headline>
                        <p>{t("configuration.registration.yes.text")}</p>
                    </div>
                </Col2>
                <Col2>
                    <div className={classNames("card-select", {"selected": configuration.registrationService === false})}
                         onClick={() => updateConfiguration({registrationService: false})}>
                        <Headline level={3}>{t("configuration.registration.no.headline")}</Headline>
                        <p>{t("configuration.registration.no.text")}</p>
                    </div>
                </Col2>
            </Row>
        </>
    )
}

export default Configure2;