import React, {useContext} from "react";
import {Product} from "@shopware-pwa/types";
import {faHeart as faHeartReg} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons"
import {Link} from "react-router-dom";
import {Button} from "antd";
import DOMPurify from 'dompurify';

import LocalizationContext from "../context/LocalizationContext";
import ShopwareContext from "../context/ShopwareContext";
import Currency from "./Currency";
import ShopwareThumbnail from "./ShopwareThumbnail";
import {ROUTE_PRODUCT} from "../../constant/route";
import NavButton from "../layout/NavButton";
import StrapiContext from "../context/StrapiContext";
import "./ProductBox.scss";

interface Props {
    product: Product,
    noActions?: boolean,
    onClick?: () => void,
    style?: React.CSSProperties
}

const ProductBox = ({product, onClick, noActions, style}: Props) => {
    const {t} = useContext(LocalizationContext)
    const {isOnWishlist, addToWishlist, removeFromWishlist} = useContext(ShopwareContext);
    const {addToCart} = useContext(StrapiContext);

    const onWishlist = isOnWishlist(product.id);

    const toggleWishlist = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        onWishlist ? removeFromWishlist(product.id) : addToWishlist(product.id)
    }

    return (
        <div className={"product-box"} onClick={onClick} style={style}>
            <div className={"product-image"}>
                {product.cover.media && <ShopwareThumbnail media={product.cover.media}/>}
            </div>
            <div className={"product-info"}>
                <div className={"product-name"}>
                    {noActions
                        ? product.translated.name
                        : (
                            <Link to={ROUTE_PRODUCT.replace(":id", product.id)}>
                                {product.translated.name}
                            </Link>
                        )}
                </div>
                <div className={"product-number"}>{product.productNumber}</div>
                <div className={"product-description hyphenate"}>
                    {DOMPurify.sanitize(product.translated.description, {ALLOWED_TAGS: []})}
                </div>
            </div>
            <div className={"product-price"}>
                <strong><Currency value={product.calculatedPrice.unitPrice}/></strong>
            </div>
            {!noActions &&
                <div className={"product-action"}>
                    {product.available
                        ? <Button onClick={() => addToCart(product.id)} type={"primary"}>
                            {t("productBox.addToCart")}</Button>
                        : <NavButton to={ROUTE_PRODUCT.replace(":id", product.id)} type={"default"}>
                            {t("productBox.detail")}</NavButton>}
                </div>}
            <div className={"product-wishlist"} onClick={toggleWishlist}>
                <FontAwesomeIcon icon={onWishlist ? faHeart : faHeartReg}/>
            </div>
        </div>
    )
}

export default ProductBox;