import React from "react";
import {Category, CmsSection} from "@shopware-pwa/types";
import Block from "./Block";

interface Props {
    section: CmsSection,
    category: Category
}

const Section = ({section, category}: Props) => {
    // TODO background stuff
    return (
        <div className={"ssys-section mode-" + section.sizingMode + " " + (section.cssClass ?? "")}>
            {section.blocks && section.blocks.map(block =>
                <Block key={block.position} block={block} category={category}/>)}
        </div>
    )
}

export default Section;