interface Props {
    value?: number
}

const Currency = ({value}: Props) => {
    if (value === undefined) return <></>;

    let formatter = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    // TODO read context currency
    return <span style={{whiteSpace: "nowrap"}}>{formatter.format(value) + " €"}</span>
}

export default Currency;