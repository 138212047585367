import {Button, Card, Checkbox, Col, Row} from "antd";
import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

import StrapiContext from "../../context/StrapiContext";
import {StrapiJsonLineItem} from "../../../type/StrapiLineItem";
import ShopwareContext from "../../context/ShopwareContext";
import ShopwareThumbnail from "../../shared/ShopwareThumbnail";
import Currency from "../../shared/Currency";
import CartQuantity from "./CartQuantity";

interface Props {
    lineItem: StrapiJsonLineItem,
    canModify: boolean,
    bordered?: boolean,
    selected?: boolean,
    onToggleSelect?: () => void
}

const CartProduct = ({lineItem, canModify, bordered, selected, onToggleSelect}: Props) => {
    const {removeCartItem} = useContext(StrapiContext)
    const {cartProducts} = useContext(ShopwareContext);

    const product = cartProducts.find(p => p.id === lineItem.shopwareProductId);

    return (
        <Card bordered={bordered}>
            <Row justify="space-between" align={"middle"}>
                <Col span={1}>
                    {onToggleSelect && <Checkbox checked={selected} onChange={onToggleSelect}/>}
                </Col>
                <Col span={2}>
                    {product && product.cover && <ShopwareThumbnail media={product.cover.media}/>}
                </Col>
                <Col span={7}>
                    {product && <>
                        <strong>{product.translated.name}</strong><br/>
                        {product.productNumber}
                    </>}
                </Col>
                <Col span={2} style={{textAlign: "right"}}>
                    {product && <Currency value={product.calculatedPrice.unitPrice}/>}
                </Col>
                <Col span={5} style={{whiteSpace: "nowrap", textAlign: "center"}}>
                    {canModify
                        ? <CartQuantity lineItem={lineItem}/>
                        : lineItem.quantity}
                </Col>
                <Col span={2} style={{textAlign: "right"}}>
                    {product && <Currency value={product.calculatedPrice.unitPrice * lineItem.quantity}/>}
                </Col>
                <Col span={2} style={{textAlign: "right"}}>
                    {canModify &&
                        <Button danger={true}
                            onClick={() => removeCartItem(lineItem.shopwareProductId)} disabled={!canModify}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </Button>}
                </Col>
            </Row>
        </Card>
    )
}

export default CartProduct;