import React, {useContext, useState} from "react";
import {Button, Card, Space, TableProps} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

import VehicleForm from "../../form/VehicleForm";
import {StrapiEntity, StrapiId, StrapiNested} from "../../../type/StrapiApi";
import StrapiContext from "../../context/StrapiContext";
import Headline from "../../layout/Headline";
import LocalizationContext from "../../context/LocalizationContext";
import {StrapiVehicle} from "../../../type/StrapiVehicle";
import Breadcrumbs from "../../layout/Breadcrumbs";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";
import {StrapiModel} from "../../../type/StrapiModel";
import VehicleDetail from "./VehicleDetail";
import {formatDateMonth} from "./util";
import "./Vehicles.scss";
import {compareNumbers, compareStrings} from "../../../util/sort";

type VehicleRecord = StrapiVehicle & { id: StrapiId };

const Vehicles = () => {
    const {t} = useContext(LocalizationContext);
    const {vehicles} = useContext(StrapiContext);

    const [create, setCreate] = useState<boolean>(false);
    const [edit, setEdit] = useState<StrapiEntity<StrapiVehicle>>()
    const [view, setView] = useState<StrapiEntity<StrapiVehicle>>();

    const close = () => {
        setCreate(false)
        setEdit(undefined)
        setView(undefined)
    }

    const add = (
        <Button onClick={() => setCreate(true)} type={"primary"}>
            <FontAwesomeIcon icon={faPlus} style={{marginRight: ".5rem"}}/>{t("form.action.create")}
        </Button>
    )

    const columns: TableProps["columns"] = [{
        title: t("form.vehicle.number"),
        key: "number",
        dataIndex: "number",
        sorter: compareStrings(x => x.number)
    }, {
        title: t("form.vehicle.model"),
        key: "model",
        dataIndex: "model",
        render: (model: StrapiNested<StrapiModel>, vehicle: StrapiVehicle) => {
            const manufacturer = model?.data?.attributes.manufacturer;
            return (
                <>
                    {manufacturer?.data?.attributes.name}
                    {" "}
                    {model?.data?.attributes.name}
                </>
            )
        }
    }, {
        title: t("form.vehicle.registration"),
        key: "registration",
        dataIndex: "registration",
        render: date => formatDateMonth(date),
        sorter: compareStrings(x => x.registration)
    }, {
        title: t("form.vehicle.inspection"),
        key: "inspection",
        dataIndex: "inspection",
        render: date => date && formatDateMonth(date),
        sorter: compareStrings(x => x.inspection)
    }, {
        title: t("form.vehicle.vin"),
        key: "vin",
        dataIndex: "vin",
    }, {
        title: t("form.vehicle.kilometers"),
        key: "kilometers",
        dataIndex: "kilometers",
        sorter: compareNumbers(x => x.kilometers)
    }]

    const viewActions = view && (
        <>
            <Button type={"link"} size={"small"} onClick={close}>
                <FontAwesomeIcon icon={faTimes}/>
            </Button>
        </>
    )

    const detailActions = (
        <Space direction={"vertical"} size={"middle"} style={{width: "100%"}}>
            <Button type="primary" ghost={true} block={true}>
                {t("vehicle.calendar")}
            </Button>
            <Button type="primary" ghost={true} block={true}>
                {t("vehicle.documents")}
            </Button>
            <Button type="primary" ghost={true} block={true} onClick={() => setEdit(view)}>
                {t("vehicle.edit")}
            </Button>
        </Space>
    )

    const dataSource = (vehicles ?? [])
        .map(v => ({id: v.id, ...v.attributes}))

    return (
        <div className={"vehicles"}>
            <Breadcrumbs/>
            <Headline actions={add}>{t("vehicle.headline")}</Headline>
            <Card>
                <Table columns={columns} dataSource={dataSource}
                       rowClassName={x => x.id === view?.id ? TABLE_ROW_BOLD : ""}
                       onRow={(vehicle: VehicleRecord) => {
                           return {
                               onClick: () => {
                                   setView(vehicles.find(v => v.id === vehicle.id))
                               },
                           };
                       }}/>
            </Card>
            {create
                ? <Card><VehicleForm onClose={close}/></Card>
                : edit
                    ? <Card><VehicleForm key={edit.id} vehicle={edit} onClose={close}/></Card>
                    : view
                        ? (
                            <Card title={view.attributes.number} extra={viewActions}>
                                <VehicleDetail vehicle={view} actions={detailActions}/>
                            </Card>
                        )
                        : ""}
        </div>
    )
}

export default Vehicles;