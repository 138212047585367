import React, {useContext, useState} from "react";
import {Button, Card, TableProps} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

import LocalizationContext from "../context/LocalizationContext";
import Headline from "../layout/Headline";
import Breadcrumbs from "../layout/Breadcrumbs";
import Table, {TABLE_ROW_BOLD} from "../shared/Table";
import StrapiContext from "../context/StrapiContext";
import {StrapiMessage} from "../../type/StrapiMessage";
import {StrapiEntity} from "../../type/StrapiApi";
import Date from "../shared/Date";

const Documents = () => {
    const {t} = useContext(LocalizationContext);
    const {strapiCustomer} = useContext(StrapiContext);
    const messages = strapiCustomer?.attributes.messages?.data ?? [];

    const [message, setMessage] = useState<StrapiEntity<StrapiMessage>>();

    const dataSource = (messages ?? [])
        .map(m => ({id: m.id, ...m.attributes}))

    const columns: TableProps["columns"] = [{
        key: "sender",
        dataIndex: "sender",
    }, {
        key: "subject",
        dataIndex: "subject",
    }, {
        key: "createdAt",
        dataIndex: "createdAt",
        align: "right",
        render: (createdAt: string) => <Date value={createdAt}/>
    }]

    const close = (
        <Button type={"link"} size={"small"} onClick={() => setMessage(undefined)}>
            <FontAwesomeIcon icon={faTimes}/>
        </Button>
    )

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("dashboard.messages.headline")}</Headline>
            <Card>
                <Table columns={columns} dataSource={dataSource}
                       showHeader={false} rowClassName={x => x.read ? "" : TABLE_ROW_BOLD}
                       onRow={(m) => {
                           return {onClick: () => setMessage(messages.find(message => m.id === message.id))};
                       }}/>
            </Card>
            {message &&
              <Card title={message.attributes.subject} extra={close}>
                <div style={{textAlign: "right"}}><em><Date value={message.attributes.createdAt}/></em></div>
                  {message.attributes.text}
              </Card>}
        </>
    )
}

export default Documents;