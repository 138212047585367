import React from 'react';
import {
    Category,
    Customer,
    CustomerWishlistResponse,
    EntityResult,
    Order,
    Product,
    ProductResponse,
    ShopwareSearchParams
} from "@shopware-pwa/types";
import {StrapiJsonLineItem} from "../../type/StrapiLineItem";

const DummyPromise = async <T>() => {
    return await new Promise<T>((resolve, reject) => {
        console.error('Not Implemented');
        reject(new Error('Not implemented'));
    })
}

interface ShopwareContext {
    cartProducts: Product[],
    shopwareLanguageId?: string,
    shopwareCustomer?: Customer,
    mainNavigation: Category[],
    getCategory: (id: string) => Promise<Category>,
    getProducts: (limit: number, page: number, search?: string, filter?: ShopwareSearchParams["filter"]) => Promise<EntityResult<"product", Product>>,
    getProduct: (id: string) => Promise<ProductResponse>,
    createShopOrder: (lineItems: StrapiJsonLineItem[], isConfiguration: boolean) => Promise<Order>,
    getOrder: (id: string) => Promise<Order | undefined>,
    wishlist?: CustomerWishlistResponse | null,
    wishlistQuantity: number,
    addToWishlist: (id: string) => void,
    removeFromWishlist: (id: string) => void,
    isOnWishlist: (id: string) => boolean
}

export default React.createContext<ShopwareContext>({
    mainNavigation: [],
    getCategory: DummyPromise<Category>,
    getProducts: DummyPromise<EntityResult<"product", Product>>,
    getProduct: DummyPromise<ProductResponse>,
    createShopOrder: DummyPromise<Order>,
    getOrder: DummyPromise<Order>,
    wishlistQuantity: 0,
    addToWishlist: () => {
    },
    removeFromWishlist: () => {
    },
    isOnWishlist: () => false,
    cartProducts: []
});