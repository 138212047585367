import React from 'react';
import {Order, Product} from "@shopware-pwa/types";

import {LanguageCode, StrapiCustomer} from "../../type/StrapiCustomer";
import {StrapiEntity, StrapiId} from "../../type/StrapiApi";
import {StrapiVehicle, StrapiVehicleForm} from "../../type/StrapiVehicle";
import {StrapiManufacturer} from "../../type/StrapiManufacturer";
import {StrapiModel} from "../../type/StrapiModel";
import {StrapiInventory} from "../../type/StrapiInventory";
import {StrapiConfiguration, StrapiConfigurationForm} from "../../type/StrapiConfiguration";
import {StrapiJsonLineItem} from "../../type/StrapiLineItem";
import {StrapiOrder} from "../../type/StrapiOrder";
import {DEFAULT_SHOPWARE_CONFIG, ShopwareConfig} from "../../type/ShopwareConfig";
import {DEFAULT_FRONTEND_CONFIG, FrontendConfig} from "../../type/FontendConfig";

const DummyPromise = async <T>() => {
    return await new Promise<T>((resolve, reject) => {
        console.error('Not Implemented');
        reject(new Error('Not implemented'));
    })
}

interface StrapiContext {
    shopwareConfig: ShopwareConfig,
    frontendConfig: FrontendConfig,

    strapiCustomer?: StrapiEntity<StrapiCustomer>,
    updateStrapiCustomer: (data: Partial<StrapiCustomer>) => void,
    vehicles: StrapiEntity<StrapiVehicle>[],
    inventory: StrapiEntity<StrapiInventory>[],
    manufacturers: StrapiEntity<StrapiManufacturer>[],
    configurations: StrapiEntity<StrapiConfiguration>[],
    language: LanguageCode,
    login: (username: string, password: string) => void,
    loginError?: boolean,
    logout: () => void,
    setLanguage: (language: LanguageCode) => void,
    getModels: (manufacturerId?: StrapiId) => Promise<StrapiEntity<StrapiModel>[]>,
    createVehicle: (data: StrapiVehicleForm) => Promise<void>,
    updateVehicle: (id: StrapiId, data: StrapiVehicleForm) => Promise<void>,
    getInventory: (id: StrapiId) => Promise<StrapiEntity<StrapiInventory>>,
    getOrders: () => Promise<StrapiEntity<StrapiOrder>[]>,
    saveConfiguration: (config: StrapiConfigurationForm) => Promise<void>,
    removeConfiguration: (id: StrapiId) => void,
    createOrder: (shopwareOrderId?: Order["id"], configurationId?: StrapiId) => Promise<void>,

    addToCart: (id: Product["id"], quantity?: number) => void,
    changeCartItemQuantity: (id: Product["id"], quantity: number) => void,
    removeCartItem: (id: Product["id"]) => void,
    cartQuantity: number,
    getAggregatedCart: () => StrapiJsonLineItem[],
    clearCart: (productIds: string[]) => void
}

export default React.createContext<StrapiContext>({
    shopwareConfig: DEFAULT_SHOPWARE_CONFIG,
    frontendConfig: DEFAULT_FRONTEND_CONFIG,
    updateStrapiCustomer: () => {
    },
    vehicles: [],
    inventory: [],
    manufacturers: [],
    configurations: [],
    language: LanguageCode.DE,
    login: () => {
    },
    logout: () => {
    },
    setLanguage: () => {
    },
    getModels: DummyPromise<StrapiEntity<StrapiModel>[]>,
    createVehicle: DummyPromise<void>,
    updateVehicle: DummyPromise<void>,
    getInventory: DummyPromise<StrapiEntity<StrapiInventory>>,
    getOrders: DummyPromise<StrapiEntity<StrapiOrder>[]>,
    saveConfiguration: DummyPromise<void>,
    removeConfiguration: () => {
    },
    createOrder: DummyPromise<void>,
    addToCart: () => {
    },
    cartQuantity: 0,
    changeCartItemQuantity: () => {
    },
    removeCartItem: () => {
    },
    getAggregatedCart: () => [],
    clearCart: () => {
    }
});