import {Carousel, Row} from "antd";
import React, {ReactNode} from "react";

import {CMS_GUTTER} from "../../constant/cmsConfig";
import {ColDetailFirst, ColDetailSecond} from "../layout/Grid";
import {getMediaUrl, StrapiEntity} from "../../type/StrapiApi";
import Headline from "../layout/Headline";
import Currency from "./Currency";
import InventoryTable from "./InventoryTable";
import {StrapiInventory} from "../../type/StrapiInventory";

interface Props {
    inventory: StrapiEntity<StrapiInventory>,
    additional?: ReactNode
}

const InventoryMain = ({inventory, additional}: Props) => {
    const images = inventory?.attributes.images?.data;
    const model = inventory?.attributes.model;
    const manufacturer = model?.data?.attributes.manufacturer;

    return (
        <Row gutter={CMS_GUTTER}>
            <ColDetailFirst>
                <Carousel>
                    {images && images.map(image =>
                        <img src={getMediaUrl(image)} alt={""} key={image.id}/>)}
                </Carousel>
            </ColDetailFirst>
            <ColDetailSecond>
                <Headline level={2} noMargin={true}>{manufacturer?.data?.attributes.name} {model?.data?.attributes.name}</Headline>
                <Headline level={1} noMargin={true}>{inventory.attributes.label}</Headline>
                <Headline level={2}><Currency value={inventory.attributes.price}/></Headline>
                <InventoryTable inventory={inventory}/>
                {additional}
            </ColDetailSecond>
        </Row>
    )
}

export default InventoryMain;