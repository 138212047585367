import React from "react";
import {Media} from "@shopware-pwa/types";

interface Props {
    media: Media,
    columns?: number
}

const ShopwareThumbnail = ({media, columns = 1}: Props) => {
    // TODO Thumbnail Magic
    return (
        <img src={media.url} alt={media.translated.alt ?? ""}/>
    )
}

export default ShopwareThumbnail;