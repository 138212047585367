interface Props {
    value?: number
}

const Kilometers = ({value}: Props) => {
    if (!value) return <>-</>;

    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return <>{parts.join(".") + String.fromCharCode(160) + "km"}</>
}

export default Kilometers;