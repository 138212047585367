import React, {useContext, useEffect, useState} from "react";
import {Alert} from "antd";

import LocalizationContext from "../context/LocalizationContext";
import StrapiContext from "../context/StrapiContext";
import OrderSubmit from "../shared/OrderSubmit";
import Breadcrumbs from "../layout/Breadcrumbs";
import Headline from "../layout/Headline";
import CartProduct from "./cart/CartProduct";
import CartConfiguration from "./cart/CartConfiguration";
import {StrapiId} from "../../type/StrapiApi";

const Cart = () => {
    const {strapiCustomer, configurations, cartQuantity} = useContext(StrapiContext);
    const {t} = useContext(LocalizationContext);

    const [selectedProducts, setSelectedProducts] = useState<string[]>
    ((strapiCustomer?.attributes.cart ?? []).map(l => l.shopwareProductId));

    const [selectedConfigs, setSelectedConfigs] = useState<StrapiId[]>
    (configurations.map(c => c.id));

    useEffect(() => {
        setSelectedProducts((strapiCustomer?.attributes.cart ?? []).map(l => l.shopwareProductId));
        setSelectedConfigs(configurations.map(c => c.id))
    }, [strapiCustomer, configurations])

    const toggleProduct = (productId: string) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter(id => id !== productId))
        } else {
            setSelectedProducts([...selectedProducts, productId])
        }
    }

    const toggleConfig = (configId: StrapiId) => {
        if (selectedConfigs.includes(configId)) {
            setSelectedConfigs(selectedConfigs.filter(id => id !== configId))
        } else {
            setSelectedConfigs([...selectedConfigs, configId])
        }
    }

    return (
        <div className={"cart"}>
            <Breadcrumbs/>
            <Headline>{t("cart.headline")}</Headline>

            {cartQuantity === 0 && <Alert type={"info"} message={t("cart.empty")}/>}

            {(strapiCustomer?.attributes.cart.length || 0) > 0 && <>
                <Headline level={2}>{t("cart.products")}</Headline>
                <div>
                    {strapiCustomer?.attributes.cart.map(lineItem => (
                        <CartProduct lineItem={lineItem} key={lineItem.shopwareProductId} canModify={true}
                                     selected={selectedProducts.includes(lineItem.shopwareProductId)}
                                     onToggleSelect={() => toggleProduct(lineItem.shopwareProductId)}/>
                    ))}
                </div>
            </>}

            {configurations.length > 0 && <>
                <Headline level={2}>{t("cart.configurations")}</Headline>
                {configurations.map(configuration => (
                    <CartConfiguration configuration={configuration} key={configuration.id}
                                       selected={selectedConfigs.includes(configuration.id)}
                                       onToggleSelect={() => toggleConfig(configuration.id)}/>
                ))}
            </>}

            {cartQuantity > 0 && <OrderSubmit selectedProducts={selectedProducts} selectedConfigs={selectedConfigs}/>}
        </div>
    )
}

export default Cart;