import {Card, Row} from "antd";
import React, {useContext} from "react";

import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import InventoryBox from "../../shared/InventoryBox";
import NavButton from "../../layout/NavButton";
import {ROUTE_INVENTORY} from "../../../constant/route";
import {Col4} from "../../layout/Grid";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";

const Highlights = () => {
    const {t} = useContext(LocalizationContext);
    const {inventory} = useContext(StrapiContext);
    return (
        <Card title={t("dashboard.highlights.headline")}
              extra={<NavButton type={"link"} to={ROUTE_INVENTORY}>{t("dashboard.highlights.showAll")}</NavButton>}>
            <Row gutter={VIEW_GUTTER}>
                {inventory
                    .filter(i => i.attributes.highlight)
                    .slice(0, 4)
                    .map(inventory => (
                        <Col4 key={inventory.id}>
                            <InventoryBox inventory={inventory}/>
                        </Col4>
                    ))}
            </Row>
        </Card>
    )
}

export default Highlights;