import {Alert, Card, Row, Skeleton} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {EntityResult, MultiFilter, Product} from "@shopware-pwa/types";

import LocalizationContext from "../../context/LocalizationContext";
import ShopwareContext from "../../context/ShopwareContext";
import ProductBox from "../../shared/ProductBox";
import NavButton from "../../layout/NavButton";
import {ROUTE_PRODUCTS} from "../../../constant/route";
import StrapiContext from "../../context/StrapiContext";
import {Col4} from "../../layout/Grid";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";

const Products = () => {
    const {t} = useContext(LocalizationContext);
    const {getProducts, shopwareLanguageId} = useContext(ShopwareContext);
    const {vehicles} = useContext(StrapiContext);

    const [listing, setListing] = useState<EntityResult<"product", Product>>();
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setError(false)
        setListing(undefined)

        // @ts-ignore never undefined because of filter
        const allModelPropertyIds: string[] = vehicles
            .map(v => v.attributes.model?.data?.attributes.shopwarePropertyId)
            .filter((id) => !!id);

        const filter: MultiFilter = {
            type: "multi", operator: "or",
            queries: allModelPropertyIds.map(id => {
                return {
                    type: "equals",
                    field: "properties.id",
                    value: id
                }
            })
        };

        getProducts(4, 1, "", [filter])
            .then(c => {
                setListing(c)
                setError(false)
            })
            .catch(() => setError(true))
    }, [getProducts, shopwareLanguageId, vehicles])

    return (
        <Card title={t("dashboard.products.headline")}
              extra={<NavButton type={"link"} to={ROUTE_PRODUCTS}>{t("dashboard.products.showAll")}</NavButton>}>
            {error && <Alert type={"error"} message={t("products.error")} showIcon={true}/>}
            {!error && !listing && <Skeleton active={true}/>}
            {listing &&
              <Row gutter={VIEW_GUTTER}>
                  {listing.elements.map(product => (
                      <Col4 key={product.id}>
                          <ProductBox product={product} key={product.id}/>
                      </Col4>
                  ))}
              </Row>}
        </Card>
    )
}

export default Products;