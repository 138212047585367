import React, {useContext} from "react";
import {Alert, Row, Skeleton} from "antd";

import ShopwareContext from "../context/ShopwareContext";
import LocalizationContext from "../context/LocalizationContext";
import ProductBox from "../shared/ProductBox";
import {VIEW_GUTTER} from "../../constant/cmsConfig";
import Breadcrumbs from "../layout/Breadcrumbs";
import Headline from "../layout/Headline";
import {Col4} from "../layout/Grid";

const Wishlist = () => {
    const {wishlist} = useContext(ShopwareContext)
    const {t} = useContext(LocalizationContext);

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("wishlist.headline")}</Headline>
            {wishlist === undefined && <Skeleton active={true}/>}
            {(wishlist === null || (wishlist && wishlist.products.total === 0)) &&
                <Alert type={"info"} message={t("wishlist.empty")}/>}
            <Row gutter={VIEW_GUTTER} style={{marginTop: "2rem"}}>
                {wishlist && wishlist.products.elements.map(product => (
                    <Col4 key={product.id}>
                        <ProductBox product={product} key={product.id}/>
                    </Col4>
                ))}
            </Row>
        </>
    )
}

export default Wishlist;