import {Row, theme} from "antd";
import React, {useContext} from "react";
import {Product} from "@shopware-pwa/types";

import {StrapiConfigurationForm} from "../../../type/StrapiConfiguration";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import {Col4} from "../../layout/Grid";
import ProductBox from "../../shared/ProductBox";
import Headline from "../../layout/Headline";
import LocalizationContext from "../../context/LocalizationContext";

interface Props {
    configuration: StrapiConfigurationForm,
    updateConfiguration: (configuration: Partial<StrapiConfigurationForm>) => void,
    products: Product[]
}

const Configure1 = ({products, configuration, updateConfiguration}: Props) => {
    const {t} = useContext(LocalizationContext)
    const {token: {colorPrimary}} = theme.useToken();

    const toggleProduct = (id: Product["id"]) => {
        if (configuration.cart.find(c => c.shopwareProductId === id)) {
            updateConfiguration({cart: configuration.cart.filter(c => c.shopwareProductId !== id)})
        } else {
            updateConfiguration({cart: [...configuration.cart, {shopwareProductId: id, quantity: 1}]})
        }
    }
    return (
        <>
            <Headline level={2}>{t("configuration.products.headline")}</Headline>
            <p>{t("configuration.products.text")}</p>
            <Row gutter={VIEW_GUTTER} style={{margin: "2rem 0"}}>
                {products.map(product => {
                    const selected = configuration.cart
                        .find(c => c.shopwareProductId === product.id);
                    const style: React.CSSProperties = {cursor: "pointer"}
                    if (selected) style.borderColor = colorPrimary;

                    return (
                        <Col4 key={product.id}>
                            <ProductBox product={product} noActions={true} style={style}
                                        onClick={() => toggleProduct(product.id)}/>
                        </Col4>
                    )
                })}
            </Row>
        </>
    )
}

export default Configure1;