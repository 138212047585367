import React, {useContext} from "react";
import {Row} from "antd";
import classNames from "classnames";

import LocalizationContext from "../../context/LocalizationContext";
import {StrapiConfigurationForm} from "../../../type/StrapiConfiguration";
import Headline from "../../layout/Headline";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import {Col2} from "../../layout/Grid";

interface Props {
    configuration: StrapiConfigurationForm,
    updateConfiguration: (configuration: Partial<StrapiConfigurationForm>) => void
}

const Configure3 = ({configuration, updateConfiguration}: Props) => {
    const {t} = useContext(LocalizationContext)
    return (
        <>
            <Headline level={2}>{t("configuration.transfer.headline")}</Headline>
            <p>{t("configuration.transfer.text")}</p>
            <Row gutter={VIEW_GUTTER}>
                <Col2>
                    <div className={classNames("card-select", {"selected": configuration.transferService})}
                         onClick={() => updateConfiguration({transferService: true})}>
                        <Headline level={3}>{t("configuration.transfer.yes.headline")}</Headline>
                        <p>{t("configuration.transfer.yes.text")}</p>
                    </div>
                </Col2>
                <Col2>
                    <div className={classNames("card-select", {"selected": configuration.transferService === false})}
                         onClick={() => updateConfiguration({transferService: false})}>
                        <Headline level={3}>{t("configuration.transfer.no.headline")}</Headline>
                        <p>{t("configuration.transfer.no.text")}</p>
                    </div>
                </Col2>
            </Row>
        </>
    )
}

export default Configure3;