import {StrapiId, StrapiNested} from "./StrapiApi";
import {StrapiInventory} from "./StrapiInventory";
import {StrapiCustomer} from "./StrapiCustomer";
import {StrapiOrder} from "./StrapiOrder";
import {StrapiJsonLineItem} from "./StrapiLineItem";

export enum PaymentType {
    FINANCING = "financing",
    DIRECT = "direct"
}

export enum Status {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected"
}

export interface StrapiConfiguration {
    inventory?: StrapiNested<StrapiInventory>,
    paymentType: PaymentType,
    status: Status,
    registrationService: boolean,
    transferService: boolean,
    customer: StrapiNested<StrapiCustomer>,
    order: StrapiNested<StrapiOrder>,
    cart: StrapiJsonLineItem[]
}

export interface StrapiConfigurationForm {
    inventoryId: StrapiId,
    registrationService?: boolean,
    transferService?: boolean,
    paymentType?: PaymentType,
    cart: StrapiJsonLineItem[]
}