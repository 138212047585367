import React from "react";
import {Media} from "@shopware-pwa/types";

import {DisplayMode, HorizontalAlign, SlotConfig, SlotProps, SlotValue, VerticalAlign} from "../../../type/CmsPage";
import ShopwareThumbnail from "../../shared/ShopwareThumbnail";

interface Config extends SlotConfig {
    url: SlotValue<string>,
    media: SlotValue<string>,
    newTab: SlotValue<boolean>,
    minHeight: SlotValue<string>,
    displayMode: SlotValue<DisplayMode>,
    verticalAlign: SlotValue<VerticalAlign>,
    horizontalAlign: SlotValue<HorizontalAlign>
}

const ImageSlot = ({slot, category, columns}: SlotProps) => {
    // @ts-ignore
    const config: Config = slot.translated.config;
    // @ts-ignore
    const data: { media: Media } = slot.data;

    const img = <ShopwareThumbnail media={data.media} columns={columns}/>;

    if (config.url.value) return (
        <a href={config.url.value} target={config.newTab.value ? "_blank" : "_self"} rel={"noreferrer"}>{img}</a>
    ); else return <>{img}</>;
}

export default ImageSlot;