export interface DocumentMock {
    id: string,
    topic: string,
    title: string,
    important: boolean,
    vehicle: string,
    date: string,
}

export const documentMocks = [
    {
        id: "1",
        topic: "Vertrag",
        title: "Ihr Leasingvertrag für Fahrzeug AB XY 123",
        important: true,
        vehicle: "AB XY 123",
        date: "2023-05-12"
    },
    {
        id: "2",
        topic: "Angebot",
        title: "Neues Finanzierungsangebot für Fahrzeug CD XY 456",
        important: false,
        vehicle: "CD XY 456",
        date: "2023-04-20"
    },
    {
        id: "3",
        topic: "Fahrzeugpapiere",
        title: "Ihr Fahrzeugschein für EF XY 789",
        important: true,
        vehicle: "EF XY 789",
        date: "2023-08-01"
    },
    {
        id: "4",
        topic: "Rechnung",
        title: "Ihre Rechnung für den Service am Fahrzeug GH XY 123",
        important: false,
        vehicle: "GH XY 123",
        date: "2023-06-15"
    },
    {
        id: "5",
        topic: "Versicherung",
        title: "Bestätigung Ihrer Kfz-Versicherung für Fahrzeug IJ XY 456",
        important: false,
        vehicle: "IJ XY 456",
        date: "2023-07-30"
    },
    {
        id: "6",
        topic: "Vertrag",
        title: "Vertragsverlängerung für Ihr Fahrzeug KL XY 789",
        important: true,
        vehicle: "KL XY 789",
        date: "2024-01-10"
    },
    {
        id: "7",
        topic: "Angebot",
        title: "Angebot für Winterreifen für Fahrzeug MN XY 123",
        important: false,
        vehicle: "MN XY 123",
        date: "2023-09-22"
    },
    {
        id: "8",
        topic: "Fahrzeugpapiere",
        title: "Ihre Zulassungsbescheinigung für Fahrzeug OP XY 456",
        important: false,
        vehicle: "OP XY 456",
        date: "2023-03-11"
    },
    {
        id: "9",
        topic: "Service",
        title: "Wartungsbericht für Fahrzeug QR XY 789",
        important: false,
        vehicle: "QR XY 789",
        date: "2023-05-25"
    },
    {
        id: "10",
        topic: "Vertrag",
        title: "Übertragung Ihres Vertrags auf Fahrzeug ST XY 123",
        important: true,
        vehicle: "ST XY 123",
        date: "2024-02-14"
    },
    {
        id: "11",
        topic: "Angebot",
        title: "Aktionsangebot für Inspektion für Fahrzeug UV XY 456",
        important: false,
        vehicle: "UV XY 456",
        date: "2023-10-05"
    },
    {
        id: "12",
        topic: "Rechnung",
        title: "Rechnung für Reparatur an Fahrzeug WX XY 789",
        important: false,
        vehicle: "WX XY 789",
        date: "2023-12-18"
    }
];
