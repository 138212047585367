export interface ShopwareConfig {
    baseUrl: string,
    salesChannelToken: string,
    registrationCountryId: string,
    registrationSalutationId: string,
    registrationChannelDomain: string,
    configDeliveryId: string,
    configPaymentId: string,
    productDeliveryId: string,
    productPaymentId: string
}

export const DEFAULT_SHOPWARE_CONFIG = {
    baseUrl: "http://localhost",
    salesChannelToken: "",
    registrationCountryId: "",
    registrationSalutationId: "",
    registrationChannelDomain: "",
    configDeliveryId: "",
    configPaymentId: "",
    productDeliveryId: "",
    productPaymentId: ""
}