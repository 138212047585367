import {Col, Row} from "antd";
import Kilometers from "./Kilometers";
import React, {useContext} from "react";
import LocalizationContext from "../context/LocalizationContext";
import {StrapiInventory} from "../../type/StrapiInventory";
import {StrapiEntity} from "../../type/StrapiApi";
import Date from "./Date";

interface Props {
    className?: string,
    inventory: StrapiEntity<StrapiInventory>
}

const InventoryTable = ({className, inventory}: Props) => {
    const {t} = useContext(LocalizationContext);

    return (
        <div className={className}>
            <Row>
                <Col span={12}>{t("inventory.registration")}</Col>
                <Col span={12} className={"ellipsis"}>
                    <Date value={inventory.attributes.registration} format={"MM/YYYY"} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>{t("inventory.kilometers")}</Col>
                <Col span={12} className={"ellipsis"}><Kilometers value={inventory.attributes.kilometers}/></Col>
            </Row>
            <Row>
                <Col span={12}>{t("inventory.power")}</Col>
                <Col span={12} className={"ellipsis"}>{inventory.attributes.power}</Col>
            </Row>
            <Row>
                <Col span={12}>{t("inventory.transmission")}</Col>
                <Col span={12} className={"ellipsis"}>{t("transmission." + inventory.attributes.transmission)}</Col>
            </Row>
            <Row>
                <Col span={12}>{t("inventory.color")}</Col>
                <Col span={12} className={"ellipsis"}>{inventory.attributes.color}</Col>
            </Row>
            <Row>
                <Col span={12}>{t("inventory.fuelType")}</Col>
                <Col span={12} className={"ellipsis"}>{t("fuelType." + inventory.attributes.fuelType)}</Col>
            </Row>
        </div>
    )
}

export default InventoryTable;