import React, {ReactNode} from "react";
import {Col} from "antd";

interface Props {
    children: ReactNode,
    center?: boolean,
    right?: boolean,
    margin?: boolean
}

export const ColDetailFirst = ({children}: Props) => {
    return (
        <Col xs={24} lg={10}>
            {children}
        </Col>
    )
}

export const ColDetailSecond = ({children}: Props) => {
    return (
        <Col xs={24} lg={14}>
            {children}
        </Col>
    )
}

export const Col4 = ({children}: Props) => {
    return (
        <Col xs={24} md={12} xl={6}>
            {children}
        </Col>
    )
}

export const Col3 = ({children, margin}: Props) => {
    return (
        <Col xs={24} lg={8} style={margin ? {marginBottom: "1rem"} : undefined}>
            {children}
        </Col>
    )
}

export const Col3double = ({children}: Props) => {
    return (
        <Col xs={24} lg={16}>
            {children}
        </Col>
    )
}

export const Col2 = ({children, center, right}: Props) => {
    return (
        <Col xs={24} lg={12} style={center ? {textAlign: "center"} : right ? {textAlign: "right"} : undefined}>
            {children}
        </Col>
    )
}