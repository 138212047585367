import {Row, Typography} from "antd";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Category} from "@shopware-pwa/types";

import LocalizationContext from "../context/LocalizationContext";
import ShopwareContext from "../context/ShopwareContext";
import {
    ROUTE_CALENDAR,
    ROUTE_CATEGORY,
    ROUTE_DOCUMENTS,
    ROUTE_FAQ,
    ROUTE_INVENTORY,
    ROUTE_MESSAGES,
    ROUTE_ORDERS,
    ROUTE_PAYMENT,
    ROUTE_PRODUCTS,
    ROUTE_VEHICLES
} from "../../constant/route";
import {Col3} from "./Grid";
import "./Footer.scss";

const Footer = () => {
    const {t} = useContext(LocalizationContext);
    const {mainNavigation} = useContext(ShopwareContext);
    return (
        <Row className={"footer"}>
            <Col3 margin={true}>
                <Typography.Title level={3}>{t("footer.menu1")}</Typography.Title>
                <Link to={ROUTE_VEHICLES}>{t("route." + ROUTE_VEHICLES)}</Link>
                <Link to={ROUTE_DOCUMENTS}>{t("route." + ROUTE_DOCUMENTS)}</Link>
                <Link to={ROUTE_MESSAGES}>{t("route." + ROUTE_MESSAGES)}</Link>
                <Link to={ROUTE_CALENDAR}>{t("route." + ROUTE_CALENDAR)}</Link>
            </Col3>
            <Col3 margin={true}>
                <Typography.Title level={3}>{t("footer.menu2")}</Typography.Title>
                <Link to={ROUTE_PAYMENT}>{t("route." + ROUTE_PAYMENT)}</Link>
                <Link to={ROUTE_ORDERS}>{t("route." + ROUTE_ORDERS)}</Link>
                <Link to={ROUTE_INVENTORY}>{t("route." + ROUTE_INVENTORY)}</Link>
                <Link to={ROUTE_PRODUCTS}>{t("route." + ROUTE_PRODUCTS)}</Link>
            </Col3>
            <Col3>
                <Typography.Title level={3}>{t("footer.menu3")}</Typography.Title>
                <Link to={ROUTE_FAQ}>{t("route." + ROUTE_FAQ)}</Link>
                {mainNavigation.map((category: Category) => {
                    if (category.translated.externalLink) {
                        return (
                            <a href={category.translated.externalLink} key={category.id}
                               target={category.translated.linkNewTab ? "_blank" : "_self"}
                               rel={"noreferrer"}>{category.translated.name}</a>
                        )
                    } else {
                        const route = ROUTE_CATEGORY.replace(":id", category.id);
                        return <Link to={route} key={category.id}>{category.translated.name}</Link>
                    }
                })}
            </Col3>
        </Row>
    )
}

export default Footer;