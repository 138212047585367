import {StrapiMedia, StrapiNested, StrapiNestedArray} from "./StrapiApi";
import {StrapiModel} from "./StrapiModel";

export enum Transmission {
    AUTOMATIC = "automatic",
    MANUAL = "manual"
}

export enum FuelType {
    PETROL = "petrol",
    DIESEL = "diesel",
    ELECTRIC = "electric"
}

export interface StrapiInventory {
    model?: StrapiNested<StrapiModel>,
    label: string,
    kilometers: number,
    price: number,
    color: string,
    power: string,
    transmission: Transmission,
    fuelType: FuelType,
    images?: StrapiNestedArray<StrapiMedia>,
    highlight: boolean,
    registration: string
}