import React, {useContext} from "react";

import {getMediaUrl, StrapiEntity} from "../../type/StrapiApi";
import {StrapiInventory} from "../../type/StrapiInventory";
import NavButton from "../layout/NavButton";
import {ROUTE_INVENTORY_DETAIL} from "../../constant/route";
import LocalizationContext from "../context/LocalizationContext";
import Currency from "./Currency";
import InventoryTable from "./InventoryTable";
import "./InventoryBox.scss";

interface Props {
    inventory: StrapiEntity<StrapiInventory>
}

const InventoryBox = ({inventory}: Props) => {
    const {t} = useContext(LocalizationContext);
    const images = inventory.attributes.images?.data;

    const model = inventory.attributes.model;
    const manufacturer = model?.data?.attributes.manufacturer;

    return (
        <div className={"inventory-box"}>
            <div className={"inventory-image"}>
                {images && images[0] && <img src={getMediaUrl(images[0])} alt={""}/>}
            </div>
            <div className={"inventory-info"}>
                <div className={"inventory-title"}>
                    {manufacturer?.data?.attributes.name} {model?.data?.attributes.name}
                </div>
                <div className={"inventory-subtitle"}>
                    {inventory.attributes.label}
                </div>
                <InventoryTable inventory={inventory} className={"inventory-table"}/>
                <strong><Currency value={inventory.attributes.price}/></strong>
            </div>
            <div className={"inventory-action"}>
                <NavButton to={ROUTE_INVENTORY_DETAIL.replace(":id", String(inventory.id))}
                           type={"primary"} stretch={true}>
                    {t("inventory.detail")}</NavButton>
            </div>
        </div>
    )
}

export default InventoryBox;