import React from "react";
import {SlotConfig, SlotProps} from "../../../type/CmsPage";

const TextSlot = ({slot}: SlotProps) => {

    // TODO other configurations
    // @ts-ignore
    const config: SlotConfig = slot.translated.config;

    if (config.content.source === "static") return (
        <div dangerouslySetInnerHTML={{__html: config.content.value}}/>
    )


    return (
        <>

        </>
    )
}

export default TextSlot;