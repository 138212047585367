import React, {useContext, useState} from "react";
import {Button, Card, Row, TableProps} from "antd";
import uniq from "lodash.uniq";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faTimes} from "@fortawesome/free-solid-svg-icons";

import LocalizationContext from "../context/LocalizationContext";
import Headline from "../layout/Headline";
import Breadcrumbs from "../layout/Breadcrumbs";
import Table, {TABLE_ROW_BOLD} from "../shared/Table";
import {DocumentMock, documentMocks} from "../../mock/document";
import {compareStrings} from "../../util/sort";
import {formatDate} from "./vehicle/util";
import {Col3} from "../layout/Grid";

const Documents = () => {
    const {t} = useContext(LocalizationContext);

    const [document, setDocument] = useState<DocumentMock>();

    const columns: TableProps["columns"] = [{
        title: t("documents.field.topic"),
        key: "topic",
        dataIndex: "topic",
        sorter: compareStrings(x => x.topic),
        filters: uniq(documentMocks.map(m => m.topic)).map(x => ({
            text: x,
            value: x
        })),
        onFilter: (value, record) => record.topic.indexOf(value as string) === 0,
    }, {
        title: t("documents.field.title"),
        key: "title",
        dataIndex: "title",
        sorter: compareStrings(x => x.title)
    }, {
        title: t("documents.field.vehicle"),
        key: "vehicle",
        dataIndex: "vehicle",
        sorter: compareStrings(x => x.vehicle),
        filters: uniq(documentMocks.map(m => m.vehicle)).map(x => ({
            text: x,
            value: x
        })),
        onFilter: (value, record) => record.vehicle.indexOf(value as string) === 0,
    }, {
        title: t("documents.field.date"),
        key: "date",
        dataIndex: "date",
        sorter: compareStrings(x => x.date),
        render: x => formatDate(x)
    },]

    const close = (
        <Button type={"link"} size={"small"} onClick={() => setDocument(undefined)}>
            <FontAwesomeIcon icon={faTimes}/>
        </Button>
    )

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("documents.headline")}</Headline>
            <Card>
                <Table columns={columns} dataSource={documentMocks}
                       rowClassName={x => x.important ? TABLE_ROW_BOLD : ""}
                       onRow={(d: DocumentMock) => {
                           return {onClick: () => setDocument(d)};
                       }}/>
            </Card>
            {document &&
              <Card title={document.title} extra={close}>
                <div style={{marginBottom: "2rem"}}>
                  <FontAwesomeIcon icon={faFilePdf} size={"6x"}/>
                </div>
                <Row>
                  <Col3>
                    <Button type={"primary"} ghost={true} block={true} href={"./mock.pdf"} target={"_blank"}>
                        {t("documents.open")}
                    </Button>
                  </Col3>
                </Row>
              </Card>}
        </>
    )
}

export default Documents;