import React, {useContext, useState} from "react";
import {Button, Card, Steps, StepsProps, TableProps} from "antd";
import {LoadingOutlined} from '@ant-design/icons';

import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import {compareNumbers, compareStrings} from "../../../util/sort";
import {StrapiEntity, StrapiId, StrapiNested} from "../../../type/StrapiApi";
import {StrapiModel} from "../../../type/StrapiModel";
import {StrapiVehicle} from "../../../type/StrapiVehicle";
import {formatDateMonth} from "./util";
import Breadcrumbs from "../../layout/Breadcrumbs";
import Headline from "../../layout/Headline";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";
import VehicleDetail from "./VehicleDetail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

type VehicleRecord = StrapiVehicle & { id: StrapiId };

const Service = () => {
    const {t} = useContext(LocalizationContext);
    const {vehicles} = useContext(StrapiContext);
    const [view, setView] = useState<StrapiEntity<StrapiVehicle>>();

    const columns: TableProps["columns"] = [{
        title: t("form.vehicle.number"),
        key: "number",
        dataIndex: "number",
        sorter: compareStrings(x => x.number)
    }, {
        title: t("form.vehicle.model"),
        key: "model",
        dataIndex: "model",
        render: (model: StrapiNested<StrapiModel>, vehicle: StrapiVehicle) => {
            const manufacturer = model?.data?.attributes.manufacturer;
            return (
                <>
                    {manufacturer?.data?.attributes.name}
                    {" "}
                    {model?.data?.attributes.name}
                </>
            )
        }
    }, {
        title: t("form.vehicle.registration"),
        key: "registration",
        dataIndex: "registration",
        render: date => formatDateMonth(date),
        sorter: compareStrings(x => x.registration)
    }, {
        title: t("form.vehicle.inspection"),
        key: "inspection",
        dataIndex: "inspection",
        render: date => date && formatDateMonth(date),
        sorter: compareStrings(x => x.inspection)
    }, {
        title: t("form.vehicle.vin"),
        key: "vin",
        dataIndex: "vin",
    }, {
        title: t("form.vehicle.kilometers"),
        key: "kilometers",
        dataIndex: "kilometers",
        sorter: compareNumbers(x => x.kilometers)
    }]

    const steps: StepsProps["items"] = [
        {
            title: 'Annahme',
            subTitle: '09:45 Uhr',
            status: 'finish',
        },
        {
            title: 'Einlieferung Werkstatt',
            subTitle: '10:15 Uhr',
            status: 'finish',
        },
        {
            title: 'Finaler Check',
            subTitle: '10:45 Uhr',
            status: 'process',
            icon: <LoadingOutlined/>
        },
        {
            title: 'Bereit zur Abholung',
            subTitle: 'voraussichtlich 11:30 Uhr',
            status: 'wait',
        },
    ]

    const dataSource = (vehicles ?? [])
        .map(v => ({id: v.id, ...v.attributes}))

    const viewActions = view && (
        <>
            <Button type={"link"} size={"small"} onClick={() => setView(undefined)}>
                <FontAwesomeIcon icon={faTimes}/>
            </Button>
        </>
    )

    const detailActions = (
        <Steps items={steps} type="navigation" current={2} status={"process"} direction="vertical"/>
    )

    return (
        <div className={"vehicles"}>
            <Breadcrumbs/>
            <Headline>{t("service.headline")}</Headline>
            <Card>
                <Table columns={columns} dataSource={dataSource}
                       rowClassName={x => x.id === view?.id ? TABLE_ROW_BOLD : ""}
                       onRow={(vehicle: VehicleRecord) => {
                           return {
                               onClick: () => {
                                   setView(vehicles.find(v => v.id === vehicle.id))
                               },
                           };
                       }}/>
            </Card>
            {view && <Card title={view.attributes.number} extra={viewActions}>
              <VehicleDetail vehicle={view} actions={detailActions}/>
            </Card>}
        </div>
    )
}

export default Service;