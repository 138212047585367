import React, {useContext, useEffect, useState} from "react";
import {Alert, Button, DatePicker, Flex, Form, Input, InputNumber, Select} from 'antd';

import LocalizationContext from "../context/LocalizationContext";
import {convertVehicleFormData, StrapiVehicle, StrapiVehicleForm} from "../../type/StrapiVehicle";
import {StrapiEntity} from "../../type/StrapiApi";
import {StrapiModel} from "../../type/StrapiModel";
import StrapiContext from "../context/StrapiContext";
import {StrapiError} from "strapi-sdk-js";

interface Props {
    vehicle?: StrapiEntity<StrapiVehicle>,
    onClose: () => void
}


const VehicleForm = ({vehicle, onClose}: Props) => {
    const {t, tStr} = useContext(LocalizationContext);
    const {manufacturers, getModels, createVehicle, updateVehicle} = useContext(StrapiContext);

    const [models, setModels] = useState<StrapiEntity<StrapiModel>[]>([]);
    const [error, setError] = useState<StrapiError["error"]>();

    const [form] = Form.useForm<StrapiVehicleForm>();
    const manufacturerId = Form.useWatch('manufacturerId', form);
    const modelId = Form.useWatch('modelId', form);

    useEffect(() => {
        if (manufacturerId) {
            getModels(manufacturerId).then(models => {
                if (modelId && !models.find(m => m.id === modelId)) {
                    form.setFieldValue("modelId", undefined)
                }
                setModels(models)
            })
        } else {
            setModels([])
        }
    }, [form, getModels, manufacturerId, modelId])

    const onSubmit = (values: StrapiVehicleForm) => {
        (vehicle ? updateVehicle(vehicle.id, values) : createVehicle(values))
            .then(onClose)
            .catch((error: StrapiError["error"]) => setError(error))
    }

    const manufacturerOptions = manufacturers.map(m => {
        return {value: m.id, label: m.attributes.name}
    });

    const modelOptions = models.map(m => {
        return {value: m.id, label: m.attributes.name}
    })

    return (
        <Form form={form} name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}}
              onFinish={onSubmit} initialValues={vehicle ? convertVehicleFormData(vehicle.attributes) : undefined}>
            <Form.Item<string> label={t("form.vehicle.number")} name="number"
                               rules={[{
                                   required: true,
                                   message: t("form.validation.missing", {field: tStr("form.vehicle.number")})
                               }]}>
                <Input/>
            </Form.Item>
            <Form.Item<string> label={t("form.model.manufacturer")} name="manufacturerId"
                               rules={[{
                                   required: true,
                                   message: t("form.validation.missing", {field: tStr("form.model.manufacturer")})
                               }]}>
                <Select options={manufacturerOptions}/>
            </Form.Item>
            <Form.Item<string> label={t("form.vehicle.model")} name="modelId"
                               rules={[{
                                   required: true,
                                   message: t("form.validation.missing", {field: tStr("form.vehicle.model")})
                               }]}>
                <Select options={modelOptions} disabled={!modelOptions.length}/>
            </Form.Item>
            <Form.Item<string> label={t("form.vehicle.vin")} name="vin"
                               rules={[{
                                   required: true,
                                   message: t("form.validation.missing", {field: tStr("form.vehicle.vin")})
                               }]}>
                <Input/>
            </Form.Item>
            <Form.Item<string> label={t("form.vehicle.registration")} name="registrationDate"
                               rules={[{
                                   required: true,
                                   message: t("form.validation.missing", {field: tStr("form.vehicle.registration")})
                               }]}>
                <DatePicker format={"YYYY-MM-DD"}/>
            </Form.Item>
            <Form.Item<string> label={t("form.vehicle.inspection")} name="inspectionDate">
                <DatePicker format={"YYYY-MM-DD"}/>
            </Form.Item>
            <Form.Item<string> label={t("form.vehicle.label")} name="label">
                <Input/>
            </Form.Item>
            <Form.Item<string> label={t("form.vehicle.kilometers")} name="kilometers">
                <InputNumber min={0}/>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Flex wrap="wrap" gap="small">
                    <Button onClick={onClose}>
                        {t("form.action.cancel")}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t(vehicle ? "form.action.update" : "form.action.create")}
                    </Button>
                </Flex>
            </Form.Item>
            {error && <Alert message={error.name} description={error.message} type={"error"} showIcon={true}/>}
        </Form>
    )
}

export default VehicleForm;