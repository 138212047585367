import {Input} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useContext} from "react";

import StrapiContext from "../../context/StrapiContext";
import {StrapiJsonLineItem} from "../../../type/StrapiLineItem";

interface Props {
    lineItem: StrapiJsonLineItem,
}

const CartQuantity = ({lineItem}: Props) => {
    const {changeCartItemQuantity} = useContext(StrapiContext)

    const before = (
        <span style={lineItem.quantity > 1 ? {cursor: "pointer"} : undefined}
              onClick={lineItem.quantity > 1
                  ? () => changeCartItemQuantity(lineItem.shopwareProductId, lineItem.quantity - 1)
                  : undefined}>
            <FontAwesomeIcon icon={faMinus}/>
        </span>
    )

    const after = (
        <span style={{cursor: "pointer"}}
              onClick={() => changeCartItemQuantity(lineItem.shopwareProductId, lineItem.quantity + 1)}>
            <FontAwesomeIcon icon={faPlus}/>
        </span>
    )

    return (
        <Input type={"number"} value={lineItem.quantity} min={1} step={1} addonBefore={before} addonAfter={after}
               style={{width: "150px", textAlign: "center"}}
               onChange={e => changeCartItemQuantity(lineItem.shopwareProductId, parseInt(e.target.value))}/>

    )
}

export default CartQuantity;