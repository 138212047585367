export type StrapiId = string | number;

type AdditionalStrapiAttributes = {
    [key:string]: any
}

type StrapiAttributes<T extends AdditionalStrapiAttributes> = T & {
    createdAt: string,
    updatedAt: string,
    publishedAt?: string,
};

export interface StrapiNested<T extends AdditionalStrapiAttributes> {
    data?: StrapiEntity<T>
}

export interface StrapiNestedArray<T extends AdditionalStrapiAttributes> {
    data?: StrapiEntity<T>[]
}

export interface StrapiMedia {
    name: string,
    alternativeText?: string,
    caption?: string,
    width: number,
    height: number,
    formats?: any, // TODO what is this?
    hash: string,
    ext: string,
    mime: string,
    size: number
    url: string,
    previewUrl?: string
    provider: "local", // TODO what else?
    provider_metadata?: any,  // TODO what is this?
}

export const getMediaUrl = (media: StrapiEntity<StrapiMedia>) => {
    return process.env.REACT_APP_STRAPI_URL + media.attributes.url;
}

export interface StrapiEntity<T extends AdditionalStrapiAttributes> {
    id: StrapiId,
    attributes: StrapiAttributes<T>
}