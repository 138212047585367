export const compareNumbers =
    <T extends Record<string, any>>(getValue: (item: T) => string | number) =>
        (a: T, b: T) => {
            const aVal = getValue(a);
            const bVal = getValue(b);
            const nA = typeof aVal === "number" ? aVal : parseFloat(aVal);
            const nB = typeof bVal === "number" ? bVal : parseFloat(bVal);
            return nA - nB;
        };

export const compareStrings =
    <T extends Record<string, any>>(getValue: (item: T) => string | number) =>
        (a: T, b: T) => {
            const aVal = getValue(a);
            const bVal = getValue(b);
            const nA = typeof aVal === "string" ? aVal : String(aVal);
            const nB = typeof bVal === "string" ? bVal : String(bVal);
            return nA.localeCompare(nB, "de-DE", {sensitivity: "base"});
        };

export const compareDates =
    <T extends Record<string, any>>(getValue: (item: T) => Date) =>
        (a: T, b: T) => {
            const aDate = getValue(a).getTime();
            const bDate = getValue(b).getTime();
            return aDate - bDate;
        };

export const compareBooleans =
    <T extends Record<string, any>>(getValue: (item: T) => boolean) =>
        (a: T, b: T) => {
            const aVal = getValue(a) ? 1 : 0;
            const bVal = getValue(b) ? 1 : 0;
            return aVal - bVal;
        };