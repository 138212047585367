import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Alert, Button, Card, Skeleton, Space} from "antd";

import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import {StrapiEntity} from "../../../type/StrapiApi";
import {StrapiInventory} from "../../../type/StrapiInventory";
import Breadcrumbs from "../../layout/Breadcrumbs";
import {ROUTE_INVENTORY_CONFIGURE, ROUTE_INVENTORY_DETAIL} from "../../../constant/route";
import InventoryMain from "../../shared/InventoryMain";
import TestDrive from "./TestDrive";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface RouteParams extends Record<string, string> {
    id: string
}

const InventoryDetail = () => {
    const {t} = useContext(LocalizationContext);
    const {getInventory} = useContext(StrapiContext)
    const {id} = useParams<RouteParams>();

    const [error, setError] = useState<boolean>(false);
    const [inventory, setInventory] = useState<StrapiEntity<StrapiInventory>>()
    const [testDrive, setTestDrive] = useState(false)

    useEffect(() => {
        if (id) {
            getInventory(id)
                .then(inventory => setInventory(inventory))
                .catch(() => setError(true))
        } else {
            setInventory(undefined)
        }
    }, [getInventory, id])

    const cta = (
        <Space size={"small"} style={{marginTop: "2rem"}}>
            <Button href={id ? "#" + ROUTE_INVENTORY_CONFIGURE.replace(":id", id) : ""} type={"primary"}>
                {t("inventory.configure")}
            </Button>
            <Button onClick={() => setTestDrive(true)} type={"primary"} ghost={true}>
                {t("inventory.testDrive")}
            </Button>
        </Space>
    );

    return (
        <>
            <Breadcrumbs route={ROUTE_INVENTORY_DETAIL} title={inventory?.attributes.label ?? "..."}/>
            {error && <Alert type={"error"} message={t("category.error")} showIcon={true}/>}
            {!error && !inventory && <Skeleton active={true}/>}
            {inventory &&
              <InventoryMain inventory={inventory} additional={cta}/>}
            {testDrive && <Card title={t("inventory.testDrive")} style={{marginTop: "1.5rem"}}
                                extra={<Button type={"link"} onClick={() => setTestDrive(false)}><FontAwesomeIcon
                                    icon={faTimes}/></Button>}><TestDrive/></Card>}
        </>
    )
}

export default InventoryDetail;