import React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {
    faCalendarDays,
    faCar,
    faCartShopping,
    faCircleQuestion,
    faCreditCard,
    faEnvelope,
    faFileInvoiceDollar,
    faFileLines,
    faGrip,
    faScrewdriverWrench
} from "@fortawesome/free-solid-svg-icons";

import Dashboard from "../component/view/Dashboard";
import Vehicles from "../component/view/vehicle/Vehicles";
import Products from "../component/view/Products";
import Calendar from "../component/view/calendar/Calendar";
import Documents from "../component/view/Documents";
import Inventory from "../component/view/inventory/Inventory";
import Orders from "../component/view/Orders";
import FAQ from "../component/view/FAQ";
import Payments from "../component/view/Payments";
import Messages from "../component/view/Messages";
import Cart from "../component/view/Cart";
import CategoryPage from "../component/shopware/CategoryPage";
import ProductPage from "../component/shopware/ProductPage";
import Wishlist from "../component/view/Wishlist";
import InventoryConfigure from "../component/view/inventory/InventoryConfigure";
import InventoryDetail from "../component/view/inventory/InventoryDetail";
import Account from "../component/view/Account";
import Service from "../component/view/vehicle/Service";

export const ROUTE_DASHBOARD = "/";
export const ROUTE_VEHICLES = "/vehicles";
export const ROUTE_PRODUCTS = "/products";
export const ROUTE_DOCUMENTS = "/documents";
export const ROUTE_CONTACT = "/contact";
export const ROUTE_PRICE = "/price";
export const ROUTE_ORDERS = "/orders";
export const ROUTE_ACCOUNT = "/account";
export const ROUTE_FAQ = "/faq";
export const ROUTE_PAYMENT = "/payment";
export const ROUTE_MESSAGES = "/messages";
export const ROUTE_SERVICE = "/service";

export const ROUTE_CALENDAR = "/calendar";
export const ROUTE_CALENDAR_CREATE = ROUTE_CALENDAR + "/new";

export const ROUTE_INVENTORY = "/inventory";
export const ROUTE_INVENTORY_DETAIL = ROUTE_INVENTORY + "/:id";
export const ROUTE_INVENTORY_CONFIGURE = ROUTE_INVENTORY_DETAIL + "/configure";

export const ROUTE_CATEGORY = "/category/:id";
export const ROUTE_PRODUCT = "/product/:id";
export const ROUTE_CART = "/cart";
export const ROUTE_WISHLIST = "/wishlist";


export interface RouteConfig {
    route: string,
    icon?: IconProp,
    component: React.ComponentType,
    homeTile?: boolean
}

export const allRoutes: RouteConfig[] = [{
    route: ROUTE_DASHBOARD,
    icon: faGrip,
    component: Dashboard
}, {
    route: ROUTE_CALENDAR_CREATE,
    icon: faCalendarDays,
    component: Calendar,
    homeTile: true
}, {
    route: ROUTE_SERVICE,
    icon: faScrewdriverWrench,
    component: Service,
    homeTile: true
}, {
    route: ROUTE_PRODUCTS,
    icon: faCartShopping,
    component: Products,
    homeTile: true
}, {
    route: ROUTE_DOCUMENTS,
    icon: faFileLines,
    component: Documents,
    homeTile: true
}, {
    route: ROUTE_PAYMENT,
    icon: faCreditCard,
    component: Payments,
    homeTile: true
}, {
    route: ROUTE_VEHICLES,
    icon: faCar,
    component: Vehicles,
    homeTile: true
}, {
    route: ROUTE_CALENDAR,
    icon: faCalendarDays,
    component: Calendar,
}, {
    route: ROUTE_MESSAGES,
    icon: faEnvelope,
    component: Messages,
}, {
    route: ROUTE_ORDERS,
    icon: faFileInvoiceDollar,
    component: Orders
}, {
    route: ROUTE_INVENTORY,
    icon: faCar,
    component: Inventory
}, {
    route: ROUTE_FAQ,
    icon: faCircleQuestion,
    component: FAQ
}, {
    route: ROUTE_CATEGORY,
    component: CategoryPage,
}, {
    route: ROUTE_PRODUCT,
    component: ProductPage,
}, {
    route: ROUTE_CART,
    component: Cart,
}, {
    route: ROUTE_WISHLIST,
    component: Wishlist,
}, {
    route: ROUTE_INVENTORY_DETAIL,
    component: InventoryDetail,
}, {
    route: ROUTE_INVENTORY_CONFIGURE,
    component: InventoryConfigure,
}, {
    route: ROUTE_ACCOUNT,
    component: Account,
}]