import {Link} from "react-router-dom";
import {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Card, Typography} from "antd";
import Meta from "antd/es/card/Meta";

import LocalizationContext from "../../context/LocalizationContext";
import {allRoutes} from "../../../constant/route";
import "./RouteTiles.scss"


const RouteTiles = () => {
    const {t} = useContext(LocalizationContext);
    return (
        <>
            <Typography.Title level={2}>{t("dashboard.routes.headline")}</Typography.Title>
            <div className={"route-tiles"}>
                {allRoutes
                    .filter(r => r.homeTile)
                    .map(r => (
                        <Link to={r.route} className={"tile"} key={r.route}>
                            <Card
                                hoverable
                                style={{width: 200}}
                                cover={r.icon && <FontAwesomeIcon icon={r.icon} size={"6x"}/>}
                            >
                                <Meta title={t("route." + r.route)}/>
                            </Card>
                        </Link>
                    ))}
            </div>
        </>

    )
}

export default RouteTiles;