import React, {useContext} from "react";
import {Alert, Button, Checkbox, Form, Input} from 'antd';

import LocalizationContext from "../context/LocalizationContext";
import StrapiContext from "../context/StrapiContext";
import {clearStorageValue, getStorageValue, LOCAL_STORAGE_LOGIN, setStorageValue} from "../../util/localStorage";

const LoginForm = () => {
    const {t} = useContext(LocalizationContext);
    const {login, loginError} = useContext(StrapiContext);

    const [form] = Form.useForm();

    const onFinish = (values: { email: string, password: string, remember: boolean }) => {
        if (values.remember) setStorageValue(LOCAL_STORAGE_LOGIN, values)
        else clearStorageValue(LOCAL_STORAGE_LOGIN);

        login(values.email, values.password)
    }

    return (
        <Form form={form} name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}}
              initialValues={getStorageValue(LOCAL_STORAGE_LOGIN)} onFinish={onFinish}>
            <Form.Item<string> label={t("login.email")} name="email"
                               rules={[{required: true, message: t("login.emailMissing")}]}>
                <Input/>
            </Form.Item>
            <Form.Item<string> label={t("login.password")} name="password"
                               rules={[{required: true, message: t("login.passwordMissing")}]}>
                <Input.Password/>
            </Form.Item>
            <Form.Item<boolean> name="remember" valuePropName="checked"
                                wrapperCol={{offset: 8, span: 16}}>
                <Checkbox>{t("login.remember")}</Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type="primary" htmlType="submit">
                    {t("login.submit")}
                </Button>
            </Form.Item>
            {loginError && <Alert message={t("login.error")} type={"error"} showIcon={true}/>}
        </Form>
    )
}

export default LoginForm;