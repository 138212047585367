import {StrapiMedia, StrapiNested} from "./StrapiApi";

export interface FrontendConfig {
    showTodos: boolean,
    headerLogo?: StrapiNested<StrapiMedia>,
    dashboardCalendarImage?: StrapiNested<StrapiMedia>,
    dashboardModelImage?: StrapiNested<StrapiMedia>,
    dashboardVehicleImage?: StrapiNested<StrapiMedia>,
    homeTitle?: string
}

export const DEFAULT_FRONTEND_CONFIG = {
    showTodos: false
}