import React, {CSSProperties, useContext} from "react";
import {Alert} from "antd";

import LocalizationContext from "../context/LocalizationContext";
import TextTwoColumnBlock from "./block/TextTwoColumnBlock";
import SingleSlotBlock from "./block/SingleSlotBlock";
import {BlockProps} from "../../type/CmsPage";

const InnerBlock = ({block, category}: BlockProps) => {
    const {t} = useContext(LocalizationContext);

    switch (block.type) {
        case "text":
        case "image-cover":
            return <SingleSlotBlock block={block} category={category}/>
        case "text-two-column":
            return <TextTwoColumnBlock block={block} category={category}/>
        default:
            return (
                <Alert type="warning" message={t("category.invalidBlockType", {type: block.type})}/>
            )
    }
}

const Block = ({block, category}: BlockProps) => {
    // TODO background stuff

    const style: CSSProperties = {};
    if (block.marginTop) style.marginTop = block.marginTop;
    if (block.marginBottom) style.marginBottom = block.marginBottom;
    if (block.marginLeft) style.marginLeft = block.marginLeft;
    if (block.marginRight) style.marginRight = block.marginRight;

    return (
        <div style={style} className={block.cssClass ?? undefined}>
            <InnerBlock block={block} category={category}/>
        </div>
    )
}

export default Block;