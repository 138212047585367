import React, {useContext, useEffect, useState} from "react";
import {Alert, Col, Row, Skeleton} from "antd";
import {CustomerAddress, LineItem, Order} from "@shopware-pwa/types";

import LocalizationContext from "../context/LocalizationContext";
import ShopwareContext from "../context/ShopwareContext";
import {VIEW_GUTTER} from "../../constant/cmsConfig";
import {Col3} from "../layout/Grid";
import Headline from "../layout/Headline";
import Currency from "../shared/Currency";
import {StrapiNested} from "../../type/StrapiApi";
import {StrapiConfiguration} from "../../type/StrapiConfiguration";

interface AddressProps {
    address: CustomerAddress
}

const Address = ({address}: AddressProps) => {
    return (
        <>
            {address.firstName} {address.lastName}<br/>
            {address.street}<br/>
            {address.additionalAddressLine1 && <>{address.additionalAddressLine1}<br/></>}
            {address.additionalAddressLine2 && <>{address.additionalAddressLine2}<br/></>}
            {address.zipcode} {address.city}
        </>
    )
}

interface Props {
    id: string,
    configuration?: StrapiNested<StrapiConfiguration>
}

const ShopwareOrder = ({id, configuration}: Props) => {
    const {t} = useContext(LocalizationContext);
    const {getOrder} = useContext(ShopwareContext);

    const [order, setOrder] = useState<Order>();
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setError(false)
        setOrder(undefined)

        if (id) getOrder(id)
            .then(c => setOrder(c))
            .catch(() => setError(true))
    }, [getOrder, id])

    const configPrice = configuration?.data?.attributes.inventory?.data?.attributes.price ?? 0;

    return (
        <div style={{marginTop: "1.5rem"}}>
            {error && <Alert type={"error"} message={t("orders.error")} showIcon={true}/>}
            {!error && !order && <Skeleton active={true}/>}
            {order && <Row gutter={VIEW_GUTTER}>
                <Col3>
                    <Headline level={3}>{t("orders.address")}</Headline>
                    {order.billingAddress && <Address address={order.billingAddress}/>}
                </Col3>
                <Col3>
                    <Headline level={3}>{t("orders.products")}</Headline>
                    {order.lineItems && order.lineItems.map((lineItem: LineItem) => (
                        <Row key={lineItem.id}>
                            <Col span={2}>{lineItem.quantity}x</Col>
                            <Col span={14}>
                                <strong>{lineItem.label}</strong>
                                {"productNumber" in lineItem.payload &&
                                    <><br/>{lineItem.payload.productNumber}</>}
                            </Col>
                            <Col span={8}><Currency value={lineItem.totalPrice}/></Col>
                        </Row>
                    ))}
                </Col3>
                <Col3>
                    <Headline level={3}>{t("orders.price")}</Headline>
                    <Row gutter={[10, 10]}>
                        {configuration?.data &&
                            <>
                                <Col span={12}>
                                    <strong>{t("orders.configurationTotal")}</strong>
                                </Col>
                                <Col span={12}>
                                    <Currency value={configPrice}/>
                                </Col>
                            </>}
                        <Col span={12}>
                            <strong>{t("orders.positionsTotal")}</strong>
                        </Col>
                        <Col span={12}>
                            <Currency value={order.positionPrice}/>
                        </Col>
                        <Col span={12}>
                            <strong>{t("orders.shippingTotal")}</strong>
                        </Col>
                        <Col span={12}>
                            <Currency value={order.shippingTotal}/>
                        </Col>
                        <Col span={12}>
                            <strong>{t("orders.total")}</strong>
                        </Col>
                        <Col span={12}>
                            <strong><Currency value={order.amountTotal + configPrice}/></strong>
                        </Col>
                    </Row>
                </Col3>
            </Row>}
        </div>
    )
}

export default ShopwareOrder;