import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {EntityResult, Product} from "@shopware-pwa/types";
import {Alert, Button, Col, Row, Skeleton, Steps} from "antd";

import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import {StrapiEntity} from "../../../type/StrapiApi";
import {StrapiInventory} from "../../../type/StrapiInventory";
import Breadcrumbs from "../../layout/Breadcrumbs";
import {ROUTE_CART, ROUTE_INVENTORY_CONFIGURE} from "../../../constant/route";
import {StrapiConfigurationForm} from "../../../type/StrapiConfiguration";
import Configure1 from "../configure/Configure1";
import Configure4 from "../configure/Configure4";
import Configure2 from "../configure/Configure2";
import Configure3 from "../configure/Configure3";
import InventoryMain from "../../shared/InventoryMain";
import Summary from "../configure/Summary";
import ShopwareContext from "../../context/ShopwareContext";
import useViewportWidth from "../../hook/useViewportWidth";
import "./InventoryConfigure.scss";

interface RouteParams extends Record<string, string> {
    id: string
}

const InventoryDetail = () => {
    const {t} = useContext(LocalizationContext);
    const {getInventory, saveConfiguration} = useContext(StrapiContext)
    const viewportWidth = useViewportWidth();

    const {id} = useParams<RouteParams>();
    const {getProducts, shopwareLanguageId} = useContext(ShopwareContext);

    const [error, setError] = useState<boolean>(false);
    const [inventory, setInventory] = useState<StrapiEntity<StrapiInventory>>()
    const [listing, setListing] = useState<EntityResult<"product", Product>>();

    const [configuration, setConfiguration] = useState<StrapiConfigurationForm>();
    const [step, setStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    const updateConfig = (x: Partial<StrapiConfigurationForm>) => {
        setConfiguration({...configuration!, ...x})
    }

    useEffect(() => {
        if (inventory) setConfiguration({inventoryId: inventory.id, cart: []})
        else setConfiguration(undefined)
    }, [inventory])

    useEffect(() => {
        if (id) {
            getInventory(id)
                .then(inventory => setInventory(inventory))
                .catch(() => setError(true))
        } else {
            setInventory(undefined)
        }
    }, [getInventory, id])

    const propertyId = inventory?.attributes.model?.data?.attributes.shopwarePropertyId;

    useEffect(() => {
        setListing(undefined)
        setError(false)

        if (propertyId) {
            getProducts(12, 1, "", [{type: "equals", field: "properties.id", value: propertyId}])
                .then(c => setListing(c))
                .catch(() => setError(true))
        }
    }, [getProducts, propertyId, shopwareLanguageId])

    const submit = () => {
        if (configuration) {
            setLoading(true);
            saveConfiguration(configuration)
                .then(() => window.location.hash = ROUTE_CART)
        }
    }

    const invalidNext = configuration && (
        (step >= 2 && configuration.registrationService === undefined) ||
        (step >= 3 && configuration.transferService === undefined) ||
        (step >= 4 && configuration.paymentType === undefined)
    );

    return (
        <>
            <Breadcrumbs route={ROUTE_INVENTORY_CONFIGURE} id={id}
                         title={inventory?.attributes.label ?? "..."}/>

            {inventory && <InventoryMain inventory={inventory}/>}

            <Steps current={step - 1} style={{marginTop: "1.5rem", marginBottom: "1.5rem"}}
                   responsive={false} direction={viewportWidth > 992 ? "horizontal" : "vertical"}
                   items={[
                       {title: t("configuration.step.1")},
                       {title: t("configuration.step.2")},
                       {title: t("configuration.step.3")},
                       {title: t("configuration.step.4")},
                       {title: t("configuration.step.5")},
                   ]}/>

            {error && <Alert type={"error"} message={t("configuration.error")} showIcon={true}/>}
            {!error && (!inventory || !configuration) && <Skeleton active={true}/>}

            {configuration &&
                <>
                    {step === 1 && !listing && <Skeleton active={true}/>}
                    {step === 1 && listing &&
                        <Configure1 configuration={configuration} updateConfiguration={updateConfig}
                                    products={listing.elements}/>}
                    {step === 2 &&
                        <Configure2 configuration={configuration} updateConfiguration={updateConfig}/>}
                    {step === 3 &&
                        <Configure3 configuration={configuration} updateConfiguration={updateConfig}/>}
                    {step === 4 &&
                        <Configure4 configuration={configuration} updateConfiguration={updateConfig}/>}

                    {step === 5 && !listing && <Skeleton active={true}/>}
                    {step === 5 && listing &&
                        <Summary configuration={configuration} products={listing.elements}/>}

                    <Row style={{marginTop: "2rem"}}>
                        <Col span={12}>
                            <Button disabled={step === 1} onClick={() => setStep(step - 1)}>
                                {t("configuration.prev")}
                            </Button>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            {step < 5 &&
                                <Button type={"primary"} onClick={() => setStep(step + 1)} disabled={invalidNext}>
                                    {t("configuration.next")}
                                </Button>}
                            {step === 5 &&
                                <Button type={"primary"} disabled={invalidNext} onClick={submit} loading={loading}>
                                    {t("configuration.addToCart")}
                                </Button>}
                        </Col>
                    </Row>
                </>}
        </>
    )
}

export default InventoryDetail;