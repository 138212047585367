import {PropertyGroup} from "@shopware-pwa/types";
import {Col, Row} from "antd";

import "./ProductProperties.scss";

interface Props {
    properties: PropertyGroup[]
}

const ProductProperties = ({properties}: Props) => {
    return (
        <div className={"product-properties"}>
            {properties.map(property => (
                <div className={"property-row"} key={property.id}>
                    <Row>
                        <Col span={12}>
                            {property.translated.name}
                        </Col>
                        <Col span={12}>
                            {property.options?.map(option => option.translated.name).join(", ")}
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    )
}

export default ProductProperties;