export interface PaymentMock {
    id: string,
    incident: string,
    topic: string,
    total: number,
    date: string,
    highlight?: boolean,
}

export const paymentMocks: PaymentMock[] = [
    {
        id: "1a2b3c4d",
        incident: "Zahlungseingang",
        topic: "Teileverkauf (B-AB 123)",
        total: 249.99,
        date: "2024-08-01",
        highlight: true,
    },
    {
        id: "5e6f7g8h",
        incident: "Ausgangsrechnung",
        topic: "Verlängerung Garantie (M-XY 987)",
        total: 1299.00,
        date: "2024-08-02",
    },
    {
        id: "9i0j1k2l",
        incident: "Zahlungseingang",
        topic: "Anzahlung (HH-DE 456)",
        total: 5000.00,
        date: "2024-08-03",
        highlight: false,
    },
    {
        id: "3m4n5o6p",
        incident: "Zahlungseingang",
        topic: "Teileverkauf (F-HI 123)",
        total: 199.99,
        date: "2024-08-04",
    },
    {
        id: "7q8r9s0t",
        incident: "Ausgangsrechnung",
        topic: "Verlängerung Garantie (S-LM 789)",
        total: 1099.50,
        date: "2024-08-05",
        highlight: true,
    },
    {
        id: "1u2v3w4x",
        incident: "Zahlungseingang",
        topic: "Anzahlung (M-PQ 321)",
        total: 2500.00,
        date: "2024-08-06",
    },
    {
        id: "5y6z7a8b",
        incident: "Zahlungseingang",
        topic: "Teileverkauf (B-TU 654)",
        total: 149.99,
        date: "2024-08-07",
        highlight: false,
    },
    {
        id: "9c0d1e2f",
        incident: "Ausgangsrechnung",
        topic: "Verlängerung Garantie (HH-XY 987)",
        total: 1299.00,
        date: "2024-08-08",
    },
    {
        id: "3g4h5i6j",
        incident: "Zahlungseingang",
        topic: "Anzahlung (F-MN 654)",
        total: 3000.00,
        date: "2024-08-09",
        highlight: true,
    },
    {
        id: "7k8l9m0n",
        incident: "Zahlungseingang",
        topic: "Teileverkauf (M-QR 789)",
        total: 189.99,
        date: "2024-08-10",
    },
    {
        id: "1o2p3q4r",
        incident: "Ausgangsrechnung",
        topic: "Verlängerung Garantie (B-UV 123)",
        total: 1149.00,
        date: "2024-08-11",
    },
    {
        id: "5s6t7u8v",
        incident: "Zahlungseingang",
        topic: "Anzahlung (HH-YZ 456)",
        total: 4500.00,
        date: "2024-08-12",
        highlight: true,
    }
];
