import {Product} from "@shopware-pwa/types";

interface Props {
    product: Product
}

const Availability = ({product}: Props) => {
    return (
        <div>
            TODO: Availability
        </div>
    )
}

export default Availability;