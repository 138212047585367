import {Table as AntdTable, TableProps} from "antd";
import "./Table.scss";

interface Props {
    columns: TableProps["columns"],
    dataSource: TableProps["dataSource"],
    showHeader?: TableProps["showHeader"],
    rowClassName?: TableProps["rowClassName"],
    onRow?: TableProps["onRow"],
}

export const TABLE_ROW_BOLD = "row-bold";

const Table = ({columns, dataSource, showHeader, rowClassName, onRow}: Props) => {
    return (
        <AntdTable columns={columns}
                   dataSource={dataSource}
                   showHeader={showHeader}
                   rowClassName={rowClassName}
                   onRow={onRow}
                   rowKey="id"
                   size={"small"}
                   scroll={{ x: "max-content" }}
                   pagination={{hideOnSinglePage: true}}/>
    )
}

export default Table;