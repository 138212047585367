import React, {useContext} from "react";
import {Alert} from "antd";

import LocalizationContext from "../context/LocalizationContext";
import TextSlot from "./slot/TextSlot";
import ImageSlot from "./slot/ImageSlot";
import {SlotProps} from "../../type/CmsPage";

const InnerSlot = ({slot, category, columns}: SlotProps) => {
    const {t} = useContext(LocalizationContext);

    switch (slot.type) {
        case "text":
            return <TextSlot slot={slot} category={category} columns={columns}/>;
        case "image":
            return <ImageSlot slot={slot} category={category} columns={columns}/>
        default:
            return (
                <Alert type="warning" message={t("category.invalidSlotType", {type: slot.type})}/>
            )
    }
}


const Slot = ({slot, category, columns}: SlotProps) => {
    return (
        <div className={"slot-" + slot.slot}>
            <InnerSlot slot={slot} category={category} columns={columns}/>
        </div>
    )
}

export default Slot;