import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ProductResponse} from "@shopware-pwa/types";
import {Alert, Button, Carousel, InputNumber, Row, Skeleton} from "antd";
import DOMPurify from "dompurify";

import LocalizationContext from "../context/LocalizationContext";
import ShopwareContext from "../context/ShopwareContext";
import "./ProductPage.scss";
import Headline from "../layout/Headline";
import ShopwareThumbnail from "../shared/ShopwareThumbnail";
import Availability from "../shared/Availability";
import {CMS_GUTTER} from "../../constant/cmsConfig";
import ProductVehicles from "../shared/ProductVehicles";
import Breadcrumbs from "../layout/Breadcrumbs";
import {ROUTE_PRODUCT} from "../../constant/route";
import {ColDetailFirst, ColDetailSecond} from "../layout/Grid";
import ProductProperties from "../shared/ProductProperties";
import Currency from "../shared/Currency";
import StrapiContext from "../context/StrapiContext";

interface RouteParams extends Record<string, string> {
    id: string
}

const ProductPage = () => {
    const {t} = useContext(LocalizationContext);
    const {addToCart} = useContext(StrapiContext);
    const {getProduct, shopwareLanguageId} = useContext(ShopwareContext);
    const {id} = useParams<RouteParams>();

    const [productResponse, setProductResponse] = useState<ProductResponse>();
    const [error, setError] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number>(1);

    useEffect(() => {
        setError(false)
        setProductResponse(undefined)

        if (id) getProduct(id)
            .then(c => setProductResponse(c))
            .catch(() => setError(true))
    }, [getProduct, id, shopwareLanguageId])


    const add = () => {
        if (productResponse) {
            addToCart(productResponse.product.id, quantity);
            setQuantity(1)
        }
    }

    return (
        <div className={"sys-product-page"}>
            <Breadcrumbs route={ROUTE_PRODUCT} title={productResponse?.product.translated.name ?? "..."}/>
            {error && <Alert type={"error"} message={t("product.error")} showIcon={true}/>}
            {!error && !productResponse && <Skeleton active={true}/>}
            {productResponse && <>
                <Row gutter={CMS_GUTTER}>
                    <ColDetailFirst>
                        <Carousel>
                            {productResponse.product.media.map(media =>
                                <ShopwareThumbnail media={media.media} key={media.media.id}/>)}
                        </Carousel>
                    </ColDetailFirst>
                    <ColDetailSecond>
                        <Headline>{productResponse.product.translated.name}</Headline>
                        <div style={{marginBottom: "2rem", fontSize: "1.5rem"}}>
                            <strong>
                                <Currency value={productResponse.product.calculatedPrice.unitPrice}/>
                            </strong>
                        </div>
                        <Availability product={productResponse.product}/>
                        <p>TODO: Variant Configurator</p>

                        <div style={{marginTop: "2rem"}}>
                            <InputNumber value={quantity} min={productResponse.product.minPurchase || 1} step={1}
                                         max={productResponse.product.maxPurchase ?? undefined}
                                         onChange={x => setQuantity(x || 1)}/>
                            <Button onClick={add} type={"primary"}>
                                {t("productBox.addToCart")}</Button>
                        </div>
                    </ColDetailSecond>
                </Row>

                <Headline level={2}>
                    {t("product.description")}
                </Headline>
                <div
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(productResponse.product.translated.description)}}/>

                <Headline level={2}>
                    {t("product.properties")}
                </Headline>
                {productResponse.product.sortedProperties &&
                    <ProductProperties properties={productResponse.product.sortedProperties}/>}

                <Headline level={2}>
                    {t("product.vehicles")}
                </Headline>
                {productResponse.product.propertyIds &&
                    <ProductVehicles propertyIds={productResponse.product.propertyIds}/>}
            </>}
        </div>
    )
}

export default ProductPage;