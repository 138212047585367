import React, {useContext, useEffect, useState} from "react";
import {Alert, Skeleton} from "antd";
import {StrapiError} from "strapi-sdk-js";

import LocalizationContext from "../context/LocalizationContext";
import Headline from "../layout/Headline";
import Breadcrumbs from "../layout/Breadcrumbs";
import StrapiContext from "../context/StrapiContext";
import {StrapiEntity} from "../../type/StrapiApi";
import {StrapiOrder} from "../../type/StrapiOrder";
import OrderData from "../shared/OrderData";

const Orders = () => {
    const {t} = useContext(LocalizationContext);
    const {getOrders} = useContext(StrapiContext);

    const [orders, setOrders] = useState<StrapiEntity<StrapiOrder>[]>();
    const [error, setError] = useState<StrapiError["error"]>();

    useEffect(() => {

        getOrders()
            .then(orders => setOrders(orders))
            .catch(error => setError(error))
    }, [getOrders])

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("orders.headline")}</Headline>
            {error && <Alert type={"error"} message={t("orders.error")} showIcon={true}/>}
            {!error && !orders && <Skeleton active={true}/>}
            {orders && orders.map(order => (
                <OrderData order={order} key={order.id}/>
            ))}
        </>
    )
}

export default Orders;