import dayjs from "dayjs";

interface Props {
    value?: string,
    format?: string
}

const Date = ({value, format = "DD.MM.YYYY"}: Props) => {
    if (value === undefined) return <></>;

    const dateObject = dayjs(value)

    return <>{dateObject.format(format)}</>
}

export default Date;