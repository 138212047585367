import {useContext} from "react";
import {Alert, Row} from "antd";
import LocalizationContext from "../context/LocalizationContext";
import StrapiContext from "../context/StrapiContext";
import {VIEW_GUTTER} from "../../constant/cmsConfig";
import VehicleBox from "./VehicleBox";
import {Col4} from "../layout/Grid";

interface Props {
    propertyIds: string[]
}

const ProductVehicles = ({propertyIds}: Props) => {
    const {t} = useContext(LocalizationContext);
    const {vehicles} = useContext(StrapiContext);

    const matchingVehicles = vehicles.filter(v => {
        const propertyId = v.attributes.model?.data?.attributes.shopwarePropertyId;
        return propertyId && propertyIds.includes(propertyId)
    })
    return (
        <>
            <Row gutter={VIEW_GUTTER}>
                {matchingVehicles.map(vehicle => (
                    <Col4 key={vehicle.id}>
                        <VehicleBox vehicle={vehicle}/>
                    </Col4>
                ))}
            </Row>
            {matchingVehicles.length === 0 &&
                <Alert type={"warning"} message={t("product.vehiclesNone")}/>}
        </>
    )
}

export default ProductVehicles;