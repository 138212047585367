import React from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";

import StrapiProvider from "./context/StrapiProvider";
import ShopwareProvider from "./context/ShopwareProvider";
import LocalizationProvider from "./context/LocalizationProvider";
import Layout from "./layout/Layout";
import Theme from "./layout/Theme";

import {allRoutes} from "../constant/route";
import Login from "./view/Login";
import MessageHelper from "./context/MessageHelper";
import "./App.scss";

const routes = (
    <Routes>
        {allRoutes.map(routeConfig => {
            const Component = routeConfig.component;
            return (
                <Route path={routeConfig.route} element={<Component/>} key={routeConfig.route}/>
            )
        })}
    </Routes>
)

const App = () => {
    return (
        <StrapiProvider>
            <LocalizationProvider>
                <Theme>
                    <MessageHelper/>
                    <Login>
                        {/* only initialized after login */}
                        <HashRouter>
                            <ShopwareProvider>
                                <Layout>
                                    {routes}
                                </Layout>
                            </ShopwareProvider>
                        </HashRouter>
                    </Login>
                </Theme>
            </LocalizationProvider>
        </StrapiProvider>
    );
}

export default App;
