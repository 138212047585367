import React, {PureComponent, type ReactNode} from 'react';
import {
    Category,
    ClientApiError,
    CustomerWishlistResponse,
    Language,
    Media,
    Product,
    ShopwareSearchParams
} from "@shopware-pwa/types";
import Cookies from "js-cookie";
import {
    addProductToCart,
    addWishlistProduct,
    clearCart,
    createInstance,
    createOrder,
    getAvailableLanguages,
    getCategory,
    getOrderDetails,
    getProduct,
    getProducts,
    getStoreNavigation,
    getWishlistProducts,
    login,
    register,
    removeWishlistProduct,
    setCurrentLanguage,
    setCurrentPaymentMethod,
    setCurrentShippingMethod,
    ShopwareApiInstance
} from "@shopware-pwa/api-client";

import ShopwareContext from "./ShopwareContext";
import withStrapiCustomer, {WithStrapi} from "./withStrapi";
import {notifyError} from "../../util/event";
import {StrapiJsonLineItem} from "../../type/StrapiLineItem";

const createPw = (length: number) => {
    const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += alphanumeric.charAt(Math.floor(Math.random() * alphanumeric.length));
    }
    return result;
}

interface Props extends WithStrapi {
    children: ReactNode
}

interface State {
    cartProducts: Product[],
    languageId?: string,
    mainNavigation: Category[],
    mediaCache: { [key: string]: Media },
    languages: Language[],
    wishlist?: CustomerWishlistResponse | null
    login: boolean,
    loaded: boolean
}

class ShopwareProvider extends PureComponent<Props, State> {

    private readonly shopwareClient: ShopwareApiInstance;

    constructor(props: Props) {
        super(props);

        this.state = {
            cartProducts: [],
            mainNavigation: [],
            mediaCache: {},
            languages: [],
            languageId: Cookies.get("sw-language-id"),
            login: false,
            loaded: false
        }

        this.shopwareClient = createInstance({
            endpoint: props.shopwareConfig.baseUrl,
            accessToken: props.shopwareConfig.salesChannelToken,
            timeout: 20000,
            contextToken: Cookies.get("sw-context-token"),
            languageId: Cookies.get("sw-language-id"),
        });
    }

    componentDidMount() {
        this._createCustomer();
        this._loadCartProducts();

        getAvailableLanguages(this.shopwareClient)
            .then(l => this.setState({languages: l.elements}))
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        const {language} = this.props;

        if (this.state.login && (!this.state.loaded || prevProps.language !== language)) {
            this._loadLanguageContent();
        }

        if (prevProps.strapiCustomer.attributes.cart !== this.props.strapiCustomer.attributes.cart ||
            prevProps.configurations !== this.props.configurations) {
            this._loadCartProducts();
        }
    }

    _loadCartProducts() {
        const productIds = this.props.getAggregatedCart().map(c => c.shopwareProductId);

        if (productIds.length > 0) {
            getProducts({
                page: 1, limit: productIds.length, filter: [{type: "equalsAny", field: "id", value: productIds}],
            }, this.shopwareClient)
                .then(response => this.setState({cartProducts: response.elements}))
                .catch(error => this._handleError(error))
        } else {
            this.setState({cartProducts: []})
        }
    }

    _loadLanguageContent() {
        if (!this.state.languages.length) return;

        const shopwareLanguage = this.state.languages
            .find(l => l.translationCode && l.translationCode.code === this.props.language);

        if (shopwareLanguage) {
            Cookies.set("sw-language-id", shopwareLanguage.id)
            this.setState({languageId: shopwareLanguage.id, loaded: true})
            setCurrentLanguage(shopwareLanguage.id, this.shopwareClient)
                .then(() => {
                    this._loadWishlist();
                    this._loadNavigation();
                    this._loadCartProducts();
                })
                .catch(error => this._handleError(error))
        } else {
            console.error(this.props.language + " not found in Shopware");
        }
    }

    _createCustomer() {
        const {strapiCustomer, updateStrapiCustomer, shopwareConfig} = this.props;

        if (strapiCustomer.attributes.shopwareId && strapiCustomer.attributes.shopwarePassword) {
            this._login();
        } else {
            const pw = createPw(16);
            register({
                storefrontUrl: shopwareConfig.registrationChannelDomain,
                salutationId: shopwareConfig.registrationSalutationId,
                firstName: strapiCustomer.attributes.firstName,
                lastName: strapiCustomer.attributes.lastName,
                email: strapiCustomer.attributes.email,
                password: pw,
                billingAddress: {
                    salutationId: shopwareConfig.registrationSalutationId,
                    firstName: strapiCustomer.attributes.firstName,
                    lastName: strapiCustomer.attributes.lastName,
                    street: strapiCustomer.attributes.street,
                    zipcode: strapiCustomer.attributes.zip,
                    city: strapiCustomer.attributes.city,
                    countryId: shopwareConfig.registrationCountryId,
                }
            }, this.shopwareClient)
                .then(customer => {
                    updateStrapiCustomer({shopwareId: customer.id, shopwarePassword: pw})
                    this._login(pw);
                })
                .catch(error => this._handleError(error))
        }
    }

    _login(pw: string = this.props.strapiCustomer.attributes.shopwarePassword) {
        login({
            username: this.props.strapiCustomer.attributes.email,
            password: pw
        }, this.shopwareClient)
            .then(response => {
                Cookies.set('sw-context-token', response.contextToken);
                this.shopwareClient.update({contextToken: response.contextToken})
                this.setState({login: true});
            })
            .catch(error => this._handleError(error))
    }

    _loadWishlist() {
        getWishlistProducts({page: 1, limit: 100}, this.shopwareClient)
            .then(w => this.setState({wishlist: w}))
            .catch((error: ClientApiError) => {
                if (error.statusCode === 404) this.setState({wishlist: null}) // wishlist was not created yet
                else this._handleError(error)
            })
    }

    isOnWishlist = (id: string) => {
        if (!this.state.wishlist) return false;
        return !!this.state.wishlist.products.elements.find(p => p.id === id);
    }

    _loadNavigation() {
        getStoreNavigation({
            requestActiveId: "main-navigation",
            requestRootId: "main-navigation", depth: 3
        }, this.shopwareClient)
            .then(mainNavigation => this.setState({mainNavigation}))
            .catch(error => this._handleError(error))
    }

    loadProducts = async (limit: number, page: number, search?: string, filter?: ShopwareSearchParams["filter"]) => {
        // no main products
        const filters: ShopwareSearchParams["filter"] = [{
            "type": "multi",
            "operator": "or",
            "queries": [
                {"type": "equals", "field": "childCount", "value": null},
                {"type": "equals", "field": "childCount", "value": "0"}
            ]
        }];
        if (filter) filters.push(...filter)

        // https://developer.shopware.com/docs/guides/integrations-api/general-concepts/search-criteria.html
        return getProducts({
            page: page, limit: limit,
            filter: filters,
            term: search,
            sort: [
                {field: "name", order: "ASC", naturalSorting: true},
                {field: "productNumber", order: "ASC"}
            ],
            "total-count-mode": "exact"
        }, this.shopwareClient)
    }


    loadCategory = (id: string) => getCategory(id, this.shopwareClient);

    loadProduct = (id: string) => getProduct(id, {
        associations: {media: {}, properties: {associations: {group: {}}}}
    }, this.shopwareClient);

    loadOrder = (id: string) => getOrderDetails(id, {
        associations: {
            billingAddress: {},
            lineItems: {}
        }
    }, this.shopwareClient);

    addToWishlist = (id: string) => {
        addWishlistProduct(id, this.shopwareClient)
            .then(() => this._loadWishlist())
            .catch(() => {
            })
    }

    removeFromWishlist = (id: string) => {
        removeWishlistProduct(id, this.shopwareClient)
            .then(() => this._loadWishlist())
            .catch(() => {
            })
    }

    _getWishlistQuantity = (): number => {
        if (!this.state.wishlist) return 0;
        return this.state.wishlist.products.total;
    }

    order = async (lineItems: StrapiJsonLineItem[], isConfiguration: boolean) => {
        const {shopwareConfig} = this.props;
        try {
            await clearCart(this.shopwareClient);

            if (isConfiguration) {
                await setCurrentPaymentMethod(shopwareConfig.configPaymentId, this.shopwareClient);
                await setCurrentShippingMethod(shopwareConfig.configDeliveryId, this.shopwareClient);
            } else {
                await setCurrentPaymentMethod(shopwareConfig.productPaymentId, this.shopwareClient);
                await setCurrentShippingMethod(shopwareConfig.productDeliveryId, this.shopwareClient);
            }

            for (const lineItem of lineItems) {
                await addProductToCart(lineItem.shopwareProductId, lineItem.quantity, this.shopwareClient);
            }

            return await createOrder({}, this.shopwareClient);
        } catch (error) {
            throw error;
        }
    }

    _handleError = (response: ClientApiError) => {
        notifyError({
            title: response.messages[0].title + " (" + response.statusCode + ")",
            message: response.messages[0].detail
        })
    }

    render() {
        return (
            <ShopwareContext.Provider value={{
                cartProducts: this.state.cartProducts,
                shopwareLanguageId: this.state.languageId,
                mainNavigation: this.state.mainNavigation,
                getCategory: this.loadCategory,
                getProducts: this.loadProducts,
                getProduct: this.loadProduct,
                createShopOrder: this.order,
                getOrder: this.loadOrder,
                addToWishlist: this.addToWishlist,
                removeFromWishlist: this.removeFromWishlist,
                wishlistQuantity: this._getWishlistQuantity(),
                isOnWishlist: this.isOnWishlist,
                wishlist: this.state.wishlist
            }}>
                {this.props.children}
            </ShopwareContext.Provider>
        )
    }
}

export default withStrapiCustomer(ShopwareProvider);