import React, {ReactElement} from 'react';

export interface LocalizationContextI {
    t: (x: string, values?: Record<string, any>) => ReactElement,
    tStr: (x: string, values?: Record<string, any>) => string
}

export default React.createContext<LocalizationContextI>({
    t: (x: string): ReactElement => <>{x}</>,
    tStr: (x: string): string => x
});