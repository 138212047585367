import React, {ReactNode} from "react";
import {Button} from "antd";
import {ButtonType} from "antd/es/button/buttonHelpers";

interface Props {
    to: string,
    children: ReactNode,
    disabled?: boolean,
    ghost?: boolean,
    stretch?: boolean,
    type?: ButtonType
}

export const NavButton = ({to, type = "primary", disabled, ghost, stretch, children}: Props) => {
    return (
        <Button type={type} href={"#" + to} style={stretch ? {width: "100%"} : undefined}
                disabled={disabled} ghost={ghost}>
            {children}
        </Button>
    )
}

export default NavButton;