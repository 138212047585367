import React, {ReactNode} from "react";
import {Layout as AntdLayout} from 'antd';

import ThemeContext from "../context/ThemeContext";
import Header from "./Header";
import Footer from "./Footer";

interface Props {
    children: ReactNode
}

const Layout = ({children}: Props) => {
    return (
        <ThemeContext.Consumer>
            {({containerWidth, containerWidthInner}) => (
                <AntdLayout style={{height: "100vh", maxWidth: containerWidth, margin: "0 auto"}}>
                    <AntdLayout.Header>
                        <div style={{maxWidth: containerWidthInner, margin: "0 auto"}}>
                            <Header/>
                        </div>
                    </AntdLayout.Header>
                    <AntdLayout>
                        <AntdLayout.Content style={{padding: "0 1.25rem 1rem", overflow: "auto"}}>
                            <div style={{maxWidth: containerWidthInner, margin: "0 auto"}}>
                                {children}
                            </div>
                        </AntdLayout.Content>
                    </AntdLayout>
                    <AntdLayout.Footer>
                        <div style={{maxWidth: containerWidthInner, margin: "0 auto"}}>
                            <Footer/>
                        </div>
                    </AntdLayout.Footer>
                </AntdLayout>)}
        </ThemeContext.Consumer>
    )
}

export default Layout;