import React from 'react';
import {Alert, Button, DatePicker, Form, Input, TimePicker} from 'antd';
import {useForm} from 'antd/es/form/Form';

const TestDriveForm = () => {
    const [form] = useForm();
    const [submit, setSubmit] = React.useState(false);

    if (submit) return (
        <Alert
            message={"Vielen Dank für Ihre Anfrage, wir werden uns schnellstmöglich bei Ihnen\n" +
                "                          melden!"}/>
    )

    return (
        <div style={{maxWidth: 600, margin: '0 auto', padding: 20}}>
            <Form
                form={form}
                layout="vertical"
                onFinish={() => setSubmit(true)}
                initialValues={{
                    vehicle: 'None',
                }}
            >
                <Form.Item
                    name="testDriveDate"
                    label="Datum der Probefahrt"
                    rules={[{required: true, message: 'Bitte wählen Sie das Datum der Probefahrt aus'}]}
                >
                    <DatePicker style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="testDriveTime"
                    label="Uhrzeit der Probefahrt"
                    rules={[{required: true, message: 'Bitte wählen Sie die Uhrzeit der Probefahrt aus'}]}
                >
                    <TimePicker format="HH:mm" style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="comments"
                    label="Kommentare"
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Anfrage absenden
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default TestDriveForm;
