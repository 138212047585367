import React, {useContext} from "react";
import {Card, TableProps} from "antd";

import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";
import Date from "../../shared/Date";
import NavButton from "../../layout/NavButton";
import {ROUTE_MESSAGES} from "../../../constant/route";

const Messages = () => {
    const {t} = useContext(LocalizationContext);
    const {strapiCustomer} = useContext(StrapiContext);
    const messages = strapiCustomer?.attributes.messages?.data;

    const columns: TableProps["columns"] = [{
        key: "sender",
        dataIndex: "sender",
    }, {
        key: "subject",
        dataIndex: "subject",
    }, {
        key: "createdAt",
        dataIndex: "createdAt",
        align: "right",
        render: (createdAt: string) => <Date value={createdAt}/>
    }]

    const dataSource = (messages ?? [])
        .map(m => ({id: m.id, ...m.attributes}))

    const extra = <NavButton type={"link"} to={ROUTE_MESSAGES}>{t("dashboard.msgLink")}</NavButton>;

    return (
        <>
            {messages &&
              <Card title={t("dashboard.messages.headline")} extra={extra}>
                <Table columns={columns} dataSource={dataSource.slice(0, 3)}
                       showHeader={false} rowClassName={x => x.read ? "" : TABLE_ROW_BOLD}/>
              </Card>}
        </>
    )
}

export default Messages;