import React, {type ComponentType, PureComponent} from 'react';

import {LanguageCode, StrapiCustomer} from "../../type/StrapiCustomer";
import StrapiContext from "./StrapiContext";
import {StrapiEntity} from "../../type/StrapiApi";
import {StrapiConfiguration} from "../../type/StrapiConfiguration";
import {StrapiJsonLineItem} from "../../type/StrapiLineItem";
import {ShopwareConfig} from "../../type/ShopwareConfig";

export interface WithStrapi {
    shopwareConfig: ShopwareConfig,
    language: LanguageCode,
    strapiCustomer: StrapiEntity<StrapiCustomer>,
    updateStrapiCustomer: (data: Partial<StrapiCustomer>) => void,
    configurations: StrapiEntity<StrapiConfiguration>[],
    getAggregatedCart: () => StrapiJsonLineItem[]
}

const withStrapi = <P extends WithStrapi>(WrappedComponent: ComponentType<P>) => {
    class LanguageHoc extends PureComponent<Omit<P, keyof WithStrapi>> {
        displayName = 'withStrapi';

        render() {
            return (
                <StrapiContext.Consumer>
                    {({
                          strapiCustomer, updateStrapiCustomer, language,
                          configurations, getAggregatedCart, shopwareConfig
                      }) =>
                        <WrappedComponent {...this.props as P}
                                          strapiCustomer={strapiCustomer}
                                          updateStrapiCustomer={updateStrapiCustomer}
                                          language={language}
                                          configurations={configurations}
                                          getAggregatedCart={getAggregatedCart}
                                          shopwareConfig={shopwareConfig}
                        />}
                </StrapiContext.Consumer>
            );
        }
    }

    return LanguageHoc;
};

export default withStrapi;