import {Card, Checkbox, Row} from "antd";
import React, {useContext, useState} from "react";
import dayjs from "dayjs";

import {StrapiEntity} from "../../type/StrapiApi";
import {StrapiOrder} from "../../type/StrapiOrder";
import Headline from "../layout/Headline";
import LocalizationContext from "../context/LocalizationContext";
import ShopwareOrder from "../shopware/ShopwareOrder";
import {PaymentType, StrapiConfiguration} from "../../type/StrapiConfiguration";
import InventoryMain from "./InventoryMain";
import {Col2, Col3} from "../layout/Grid";
import {VIEW_GUTTER} from "../../constant/cmsConfig";

interface ConfigProps {
    configuration: StrapiEntity<StrapiConfiguration>
}

const Configuration = ({configuration}: ConfigProps) => {
    const {t} = useContext(LocalizationContext);
    const inventory = configuration.attributes.inventory?.data;
    return (
        <div>
            {inventory && <InventoryMain inventory={inventory}/>}
            <Row gutter={VIEW_GUTTER} style={{marginTop: "1.5rem"}}>
                <Col3>
                    <Headline level={3}>{t("configuration.registration.headline")}</Headline>
                    <div>
                        <strong>{configuration.attributes.registrationService
                            ? t("configuration.registration.yes.headline")
                            : t("configuration.registration.no.headline")
                        }</strong>
                        <div>{configuration.attributes.registrationService
                            ? t("configuration.registration.yes.text")
                            : t("configuration.registration.no.text")
                        }</div>
                    </div>
                </Col3>
                <Col3>
                    <Headline level={3}>{t("configuration.transfer.headline")}</Headline>
                    <div>
                        <strong>{configuration.attributes.transferService
                            ? t("configuration.transfer.yes.headline")
                            : t("configuration.transfer.no.headline")
                        }</strong>
                        <div>{configuration.attributes.transferService
                            ? t("configuration.transfer.yes.text")
                            : t("configuration.transfer.no.text")
                        }</div>
                    </div>
                </Col3>
                <Col3>
                    <Headline level={3}>{t("configuration.payment.headline")}</Headline>
                    <div>
                        <strong>{configuration.attributes.paymentType === PaymentType.FINANCING
                            ? t("configuration.payment.financing.headline")
                            : t("configuration.payment.direct.headline")
                        }</strong>
                        <div>{configuration.attributes.paymentType === PaymentType.FINANCING
                            ? t("configuration.payment.financing.text")
                            : t("configuration.payment.direct.text")
                        }</div>
                    </div>
                </Col3>
            </Row>
        </div>
    )
}

interface Props {
    order: StrapiEntity<StrapiOrder>
}

const OrderData = ({order}: Props) => {
    const {t} = useContext(LocalizationContext);
    const [details, setDetails] = useState<boolean>(false);

    const date = dayjs(order.attributes.createdAt)

    return (
        <Card style={{marginBottom: "1rem"}} key={order.id}>
            <Row>
                <Col2>
                    <Headline level={2} noMargin={true}>#{order.id} | {date.format("DD.MM.YYYY, HH:mm")}</Headline>
                </Col2>
                <Col2 right={true}>
                    <Checkbox checked={details} onChange={(e) => setDetails(e.target.checked)}>
                        {t("orders.showDetails")}</Checkbox>
                </Col2>
            </Row>
            {details && <div style={{marginTop: "1.5rem"}}>
                {order.attributes.configuration?.data &&
                    <Configuration configuration={order.attributes.configuration.data}/>}
                {order.attributes.shopwareOrderId &&
                    <ShopwareOrder id={order.attributes.shopwareOrderId}
                                   configuration={order.attributes.configuration}/>}
            </div>}
        </Card>
    )
}

export default OrderData;