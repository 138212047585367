import {Button, Card, Checkbox, Col, Row} from "antd";
import React, {useContext} from "react";

import {getMediaUrl, StrapiEntity} from "../../../type/StrapiApi";
import {PaymentType, StrapiConfiguration} from "../../../type/StrapiConfiguration";
import Currency from "../../shared/Currency";
import LocalizationContext from "../../context/LocalizationContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import StrapiContext from "../../context/StrapiContext";
import CartProduct from "./CartProduct";

interface Props {
    configuration: StrapiEntity<StrapiConfiguration>,
    selected?: boolean,
    onToggleSelect?: () => void
}

const CartConfiguration = ({configuration, selected, onToggleSelect}: Props) => {
    const {t} = useContext(LocalizationContext);
    const {removeConfiguration} = useContext(StrapiContext);

    const inventory = configuration.attributes.inventory;
    const images = inventory?.data?.attributes.images?.data;
    const model = inventory?.data?.attributes.model;
    const manufacturer = model?.data?.attributes.manufacturer;

    const remove = () => {
        removeConfiguration(configuration.id)
    }

    return (
        <div style={{marginBottom: "1rem"}} className={"primary-border"}>
            <Card className={"primary-bg"} bordered={false}>
                <Row justify="space-between" align={"middle"}>
                    <Col span={1}>
                        {onToggleSelect && <Checkbox checked={selected} onChange={onToggleSelect}/>}
                    </Col>
                    <Col span={2}>
                        {images && images[0] && <img src={getMediaUrl(images[0])} alt={""}/>}
                    </Col>
                    <Col span={7}>
                        <strong>{inventory?.data?.attributes.label}</strong><br/>
                        {manufacturer?.data?.attributes.name} {model?.data?.attributes.name}
                    </Col>
                    <Col span={2} style={{textAlign: "right"}}>
                        <Currency value={inventory?.data?.attributes.price}/>
                    </Col>
                    <Col span={5} style={{textAlign: "center"}}>
                        <div>{configuration.attributes.registrationService
                            ? t("configuration.registration.yes.headline")
                            : t("configuration.registration.no.headline")
                        }</div>
                        <div>{configuration.attributes.transferService
                            ? t("configuration.transfer.yes.headline")
                            : t("configuration.transfer.no.headline")
                        }</div>
                        <div>{configuration.attributes.paymentType === PaymentType.FINANCING
                            ? t("configuration.payment.financing.headline")
                            : t("configuration.payment.direct.headline")
                        }</div>
                    </Col>
                    <Col span={2} style={{textAlign: "right"}}>
                        <Currency value={inventory?.data?.attributes.price}/>
                    </Col>
                    <Col span={2} style={{textAlign: "right"}}>
                        <Button onClick={remove} danger={true}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </Button>
                    </Col>
                </Row>
            </Card>
            {configuration.attributes.cart && configuration.attributes.cart.map(c =>
                <CartProduct lineItem={c} canModify={false} bordered={false} key={c.shopwareProductId}/>)}
        </div>
    )
}

export default CartConfiguration;