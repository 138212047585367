import React, {useContext} from "react";
import {Card, Row} from "antd";

import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import Headline from "../../layout/Headline";
import {getMediaUrl} from "../../../type/StrapiApi";
import {Col2} from "../../layout/Grid";
import "./Contact.scss";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";

const Contact = () => {
    const {t} = useContext(LocalizationContext);
    const {strapiCustomer} = useContext(StrapiContext);
    if (!strapiCustomer?.attributes.contact) return null;

    const contact = strapiCustomer.attributes.contact;
    const photo = contact.data?.attributes.photo.data;
    const phone = contact.data?.attributes.phone;
    const email = contact.data?.attributes.email;

    return (
        <div className={"dashboard-contact"}>
            <Headline level={2}>{t("dashboard.contact.headline")}</Headline>
            <Row justify={"center"}>
                <Col2>
                    <Card>
                        <Row gutter={VIEW_GUTTER}>
                            <Col2 center={true}>
                                {photo && <img src={getMediaUrl(photo)} alt={""} className={"contact-photo"}/>}
                            </Col2>
                            <Col2>
                                <div className={"contact-label"}>
                                    <strong>{contact.data?.attributes.label}</strong>
                                    <br/>{contact.data?.attributes.department}
                                </div>
                                {email &&
                                    <p>{t("dashboard.contact.email")} <a href={"mailto:" + email}>{email}</a></p>}
                                {phone &&
                                    <p>{t("dashboard.contact.phone")} <a href={"tel:" +
                                        phone.replace(/\D/g, "")}>{phone}</a></p>}
                            </Col2>
                        </Row>
                    </Card>
                </Col2>
            </Row>
        </div>
    )
}

export default Contact;