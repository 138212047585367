import {theme} from "antd";
import {Theme} from "../type/Theme";

// https://ant.design/docs/react/customize-theme#api
const themeBundk: Theme = {
    themeConfig: {
        token: {
            colorPrimary: '#1552cd',
            colorLink: '#1552cd',
            colorBorder: '#e6e9eb',
            borderRadius: 0,
            colorBgContainer: '#fff',
            colorBgLayout: '#e6e9eb',
            fontSizeHeading1: 30,
            fontSizeHeading2: 24,
            fontSizeHeading3: 18
        },
        components: {
            Layout: {
                headerBg: '#ffffff',
                headerColor: '#000',
                headerHeight: 64,
                footerBg: '#000000',
            },
        },
        algorithm: theme.defaultAlgorithm
    },
    headerMenuTheme: "light",
    sidebarMenuTheme: "dark",
    colorBgSidebar: "#000",
    containerWidth: "1280px",
    colorBgBody: "#9da6af"
}

export default themeBundk;