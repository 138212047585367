import React, {useContext} from "react";

import LocalizationContext from "../context/LocalizationContext";
import Headline from "../layout/Headline";
import Breadcrumbs from "../layout/Breadcrumbs";
import {Collapse, CollapseProps} from "antd";
import {faqMocks} from "../../mock/faq";

const FAQ = () => {
    const {t} = useContext(LocalizationContext);


    const items: CollapseProps['items'] = faqMocks.map(faq => ({
        key: faq.id,
        label: faq.title,
        children: <div style={{paddingInlineStart: 24}}>{faq.text}</div>

    }))

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("faq.headline")}</Headline>
            <Collapse bordered={false} items={items}/>
        </>
    )
}

export default FAQ;