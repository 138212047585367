import React from "react";

import {getMediaUrl, StrapiEntity} from "../../type/StrapiApi";
import {StrapiVehicle} from "../../type/StrapiVehicle";
import "./VehicleBox.scss";

interface Props {
    vehicle: StrapiEntity<StrapiVehicle>
}

const VehicleBox = ({vehicle}: Props) => {
    const model = vehicle.attributes.model;
    const modelMedia = model?.data?.attributes.image.data;
    const manufacturer = model?.data?.attributes.manufacturer;
    return (
        <div className={"vehicle-box"}>
            <div className={"vehicle-image"}>
                {modelMedia && <img src={getMediaUrl(modelMedia)} alt={""}/>}
            </div>
            <div className={"vehicle-info"}>
                <strong>
                    {manufacturer?.data?.attributes.name}
                    {" "}
                    {model?.data?.attributes.name}
                </strong>
                <br/>{vehicle.attributes.label}
            </div>
        </div>
    )
}

export default VehicleBox;