import {PaymentType, StrapiConfigurationForm} from "../../../type/StrapiConfiguration";
import React, {useContext} from "react";
import Headline from "../../layout/Headline";
import LocalizationContext from "../../context/LocalizationContext";
import {Product} from "@shopware-pwa/types";
import Currency from "../../shared/Currency";
import {Card, Row} from "antd";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import {Col2} from "../../layout/Grid";

interface Props {
    configuration: StrapiConfigurationForm,
    products: Product[]
}

const Summary = ({configuration, products}: Props) => {
    const {t} = useContext(LocalizationContext)

    return (
        <>
            <Headline level={2}>{t("configuration.summary.headline")}</Headline>
            <p>{t("configuration.summary.text")}</p>

            <Row gutter={VIEW_GUTTER} style={{marginTop: "1.5rem"}}>
                <Col2>
                    <Card>
                        <Headline level={3}>{t("configuration.products.headline")}</Headline>
                        {configuration.cart.map(lineItem => {
                            const product = products
                                .find(p => p.id === lineItem.shopwareProductId);
                            return product && (
                                <div key={lineItem.shopwareProductId} style={{marginTop: "1rem"}}>
                                    <strong>{product.translated.name}</strong>{" "}
                                    (<Currency value={product.calculatedPrice.unitPrice}/>)<br/>
                                    {product.productNumber}
                                </div>
                            )
                        })}
                    </Card>
                </Col2>
                <Col2>
                    <Card>
                        <Headline level={3}>{t("configuration.registration.headline")}</Headline>
                        <div>
                            <strong>{configuration.registrationService
                                ? t("configuration.registration.yes.headline")
                                : t("configuration.registration.no.headline")
                            }</strong>
                            <div>{configuration.registrationService
                                ? t("configuration.registration.yes.text")
                                : t("configuration.registration.no.text")
                            }</div>
                        </div>
                    </Card>
                </Col2>
                <Col2>
                    <Card>
                        <Headline level={3}>{t("configuration.transfer.headline")}</Headline>
                        <div>
                            <strong>{configuration.transferService
                                ? t("configuration.transfer.yes.headline")
                                : t("configuration.transfer.no.headline")
                            }</strong>
                            <div>{configuration.transferService
                                ? t("configuration.transfer.yes.text")
                                : t("configuration.transfer.no.text")
                            }</div>
                        </div>
                    </Card>
                </Col2>
                <Col2>
                    <Card>
                        <Headline level={3}>{t("configuration.payment.headline")}</Headline>
                        <div>
                            <strong>{configuration.paymentType === PaymentType.FINANCING
                                ? t("configuration.payment.financing.headline")
                                : t("configuration.payment.direct.headline")
                            }</strong>
                            <div>{configuration.paymentType === PaymentType.FINANCING
                                ? t("configuration.payment.financing.text")
                                : t("configuration.payment.direct.text")
                            }</div>
                        </div>
                    </Card>
                </Col2>
            </Row>
        </>
    )
}

export default Summary;