import React from "react";

import Slot from "../Slot";
import {BlockProps} from "../../../type/CmsPage";

const SingleSlotBlock = ({block, category}: BlockProps) => {
    return (
        <Slot slot={block.slots[0]} category={category}/>
    )
}

export default SingleSlotBlock;