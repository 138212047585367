import React, {ReactNode, useContext} from "react";
import {Card, Layout as AntdLayout, theme} from 'antd';

import StrapiContext from "../context/StrapiContext";
import LoginForm from "../form/LoginForm";
import "./Login.scss";

interface Props {
    children: ReactNode
}

const Login = ({children}: Props) => {
    const {token: {colorBgLayout}} = theme.useToken();
    const {strapiCustomer} = useContext(StrapiContext);

    if (strapiCustomer) return <>{children}</>;
    return (
        <AntdLayout className={"ssys-login"} style={{background: colorBgLayout}}>
            <Card className={"login-form-wrapper"}>
                <LoginForm/>
            </Card>
        </AntdLayout>
    )
}

export default Login;