import {Row, Select} from "antd";
import React, {useContext, useState} from "react";

import Headline from "../../layout/Headline";
import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import InventoryBox from "../../shared/InventoryBox";
import {StrapiId} from "../../../type/StrapiApi";
import Breadcrumbs from "../../layout/Breadcrumbs";
import {Col2, Col4} from "../../layout/Grid";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";

const Inventory = () => {
    const {t} = useContext(LocalizationContext);
    const {manufacturers, inventory} = useContext(StrapiContext);

    const [manufacturerId, setManufacturerId] = useState<StrapiId>();
    const manufacturerOptions = manufacturers.map(m => {
        return {value: m.id, label: m.attributes.name}
    });

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("inventory.headline")}</Headline>
            <Row gutter={VIEW_GUTTER} style={{marginBottom: "2rem"}}>
                <Col2>
                    <Select options={manufacturerOptions} value={manufacturerId} onChange={setManufacturerId}
                            style={{width: "100%"}} allowClear={true} placeholder={t("inventory.filter.manufacturer")}/>
                </Col2>
            </Row>
            <Row gutter={VIEW_GUTTER} style={{marginBottom: "1.5rem"}}>
                {inventory.filter(i =>
                    !manufacturerId || i.attributes.model?.data?.attributes.manufacturer?.data?.id === manufacturerId)
                    .map(inventory => (
                        <Col4 key={inventory.id}>
                            <InventoryBox inventory={inventory}/>
                        </Col4>
                    ))}
            </Row>
        </>

    )
}

export default Inventory;