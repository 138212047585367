import React, {useContext, useEffect, useState} from "react";
import {EntityResult, EqualsFilter, MultiFilter, Product} from "@shopware-pwa/types";
import {Alert, Input, Pagination, Row, Select, Skeleton} from "antd";
import debounce from 'lodash.debounce';
import sortBy from "lodash.sortby";

import LocalizationContext from "../context/LocalizationContext";
import ShopwareContext from "../context/ShopwareContext";
import ProductBox from "../shared/ProductBox";
import Headline from "../layout/Headline";
import StrapiContext from "../context/StrapiContext";
import {VIEW_GUTTER} from "../../constant/cmsConfig";
import Breadcrumbs from "../layout/Breadcrumbs";
import {Col2, Col4} from "../layout/Grid";

const Products = () => {
    const {t, tStr} = useContext(LocalizationContext);
    const {vehicles} = useContext(StrapiContext);
    const {getProducts, shopwareLanguageId} = useContext(ShopwareContext);

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(12);

    const [term, setTerm] = useState<string>("");
    const [modelPropertyId, setModelPropertyId] = useState<string>()

    const [listing, setListing] = useState<EntityResult<"product", Product>>();
    const [error, setError] = useState<boolean>(false);


    useEffect(() => {
        setError(false)
        setListing(undefined)

        const debouncedFetch = debounce(async () => {
            // @ts-ignore never undefined because of filter
            const allModelPropertyIds: string[] = vehicles
                .map(v => v.attributes.model?.data?.attributes.shopwarePropertyId)
                .filter((id) => !!id);

            const filter: EqualsFilter | MultiFilter = modelPropertyId
                ? {type: "equals", field: "properties.id", value: modelPropertyId}
                : {
                    type: "multi", operator: "or",
                    queries: allModelPropertyIds.map(id => {
                        return {
                            type: "equals",
                            field: "properties.id",
                            value: id
                        }
                    })
                };

            getProducts(limit, page, term, filter ? [filter] : undefined)
                .then(c => setListing(c))
                .catch(() => setError(true))
        }, 500)

        debouncedFetch();

        return () => debouncedFetch.cancel();
    }, [getProducts, page, limit, shopwareLanguageId, term, modelPropertyId, vehicles])

    const onShowSizeChange = (current: number, pageSize: number) => setLimit(pageSize);
    const onPageChange = (page: number) => setPage(page);

    const pagination = (
        <div style={{textAlign: "center"}}>
            <Pagination
                showSizeChanger={true} pageSize={listing?.limit ? listing.limit : undefined}
                pageSizeOptions={[4, 8, 12, 16, 20, 24]} onShowSizeChange={onShowSizeChange}
                current={listing?.page || 1} onChange={onPageChange} total={listing?.total || 0}
            />
        </div>
    )

    const modelOptions = sortBy(vehicles.map(v => {
        return {
            value: v.attributes.model?.data?.attributes.shopwarePropertyId,
            label: v.attributes.model?.data?.attributes.manufacturer?.data?.attributes.name + " | " +
                v.attributes.model?.data?.attributes.name + (v.attributes.label ? " - " + v.attributes.label : "")
        }
    }), "label")

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("products.headline")}</Headline>
            <Row gutter={VIEW_GUTTER} style={{marginBottom: "1.5rem"}}>
                <Col2>
                    <Select options={modelOptions} value={modelPropertyId} onChange={setModelPropertyId}
                            style={{width: "100%"}} placeholder={t("products.vehicle")} allowClear={true}/>
                </Col2>
                <Col2>
                    <Input value={term} allowClear={true} placeholder={tStr("products.search")}
                           onChange={e => setTerm(e.target.value)}/>
                </Col2>
            </Row>
            {error && <Alert type={"error"} message={t("products.error")} showIcon={true}/>}
            {!error && !listing && <Skeleton active={true}/>}
            {listing && <>
                {pagination}
                <Row gutter={VIEW_GUTTER} style={{margin: "2rem 0"}}>
                    {listing.elements.map(product => (
                        <Col4 key={product.id}>
                            <ProductBox product={product}/>
                        </Col4>
                    ))}
                </Row>
                {pagination}
            </>}
        </>
    )
}

export default Products;